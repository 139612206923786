import { useDispatch, useSelector } from "react-redux"
import AlertMessage from "../../../../components/AlertMessage"
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Loading from "../../../../components/Loading"
import { getAdminBuyCommission, getEventDetails } from "../../../../actions/events"
import { getUrl } from "../../../../actions/url"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import RequiredField from "../../../../components/RequiredField"
import { currencySymbol, modalMessageFunc } from "../../../../helpers/helper"
import { getOrderDetails, sellOrder } from "../../../../actions/orders"
import OrderBook from "../OrderBook"
import SocketContext from "../../../../context/SocketContext"
import { useQuery } from "@tanstack/react-query"
import getOrderBook from "../../../../api/OrderManagement/getOrderBook"

const SellOrderForm = forwardRef((props, ref) => {
    const { isCreate, setIsEdit, setIsCreate,adminPermission, setSubmitDisableButton } = props
    const { id } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const eventId = searchParams.get('eventId')
    const orderId = searchParams.get('orderId')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState(false)
    const [close, setClose] = useState(false)
    const [modalMessage, setModalMessage] = useState(false)

    const [priceMarket, setPriceMarket] = useState('LIMIT')
    const [priceMarketErr, setPriceMarketErr] = useState('')
    const [selectOption, setSelectOption] = useState('')
    const [selectOptionErr, setSelectOptionErr] = useState('')
    const [priceRange, setPriceRange] = useState(1)
    const [priceRangeErr, setPriceRangeErr] = useState('')
    const [quantity, setQuantity] = useState(1)
    const [quantityErr, setQuantityErr] = useState('')
    const [marketPriceErr, setMarketPriceErr] = useState('')
    const [marketPrice, setMarketPrice] = useState(1)
    const [isOpenMarketInput, setIsOpenMarketInput] = useState(false)

    const dispatch = useDispatch()
    const eventDetails = useSelector(state => state?.events?.eventDetails)
    const resStatus = useSelector(state => state?.orders?.resStatus)
    const resMessage = useSelector(state => state?.orders?.resMessage)
    const adminCommission = useSelector(state => state?.orders?.adminCommission)
    const orderDetails = useSelector(state => state?.orders?.orderDetails)
    const previousProps = useRef({ resStatus, resMessage, eventDetails, adminCommission })?.current

    const socket = useContext(SocketContext)
    const [activeTab, setActiveTab] = useState('BUY');
    const [newOrderBookList,setNewOrderBookList] = useState()
    const { data: orderBookList } = useQuery({
        queryKey: ['getOrderBook', id || eventId, activeTab],
        queryFn: () => getOrderBook(id ||  eventId, activeTab),
        select: (res) => res?.data?.data
    })
    useEffect(()=>{
        if(orderBookList?.length){
            setNewOrderBookList(orderBookList)
        }
    },[orderBookList])
    useEffect(() => {
        if (socket) {
          socket.on('ADMIN_UPDATES', (data) => {
            if(activeTab==='BUY' && data?.eType ==='ORDER_BOOK_UPDATE'){
                setNewOrderBookList(data?.oData?.oOrderBook?.BUY?.length ? data?.oData?.oOrderBook?.BUY : orderBookList)
                // console.log(data, 'Received ADMIN_UPDATES event BUY',data?.oData?.oOrderBook?.BUY,data?.oData?.oOrderBook?.BUY?.length);
            }else if(activeTab==='SELL' && data?.eType ==='ORDER_BOOK_UPDATE'){
                setNewOrderBookList(data?.oData?.oOrderBook?.SELL?.length ? data?.oData?.oOrderBook?.SELL : orderBookList)
                // console.log(data, 'Received ADMIN_UPDATES event SELL',data?.oData?.oOrderBook?.SELL,data?.oData?.oOrderBook?.SELL?.length);
            }
          });
        }
      }, [socket]); 

    useEffect(()=>{
        if(orderId){
            dispatch(getOrderDetails( orderId ))
        }
    },[orderId])
    useEffect(()=>{
        if(orderDetails){
            setPriceMarket(orderDetails?.eOrderType)
            setSelectOption(orderDetails?.sSymbol)
            setPriceRange(orderDetails?.nPrice)
            setQuantity(orderDetails?.nQty)
        }
    },[orderDetails])
    useEffect(() => {
        if (eventId) {
            dispatch(getEventDetails({ Id: eventId }))
            setIsCreate(false)
        } else {
            setIsEdit(true)
        }
        dispatch(getAdminBuyCommission())
        dispatch(getUrl('media'))
    }, [])
    const submitDisable = () => {
        const isAdd = !eventDetails
        const commonConditions = priceMarket === '' || selectOption === '' || priceRange === '' || quantity === ''
        if (isAdd) {
            return commonConditions
        }
        return commonConditions

    }
    useEffect(() => {
        setSubmitDisableButton(submitDisable)
    }, [submitDisable])

    useEffect(() => {
        if (previousProps?.resMessage !== resMessage) {
            if (resMessage) {
                setMessage(resMessage)
                setStatus(resStatus)
                if (resStatus && isCreate) {
                    navigate(`/event-management/event-details/${eventId}`, { state: { message: resMessage } })
                } else {
                    if (resStatus) {
                        setIsEdit(false)
                        dispatch(getEventDetails({ Id: id }))
                    }
                    setModalMessage(true)
                }
                setLoading(false)
            }
        }
        return () => {
            previousProps.resMessage = resMessage
        }
    }, [resStatus, resMessage])
    useEffect(() => {
        modalMessageFunc(modalMessage, setModalMessage, setClose)
    }, [modalMessage])

    function handleChange(event, type) {
        switch (type) {
            case 'priceMarket':
                if (event?.target?.value) {
                    setPriceMarket(event?.target?.value)
                } else {
                    setPriceMarketErr('Required Field')
                }
                if(event.target.value==='MARKET'){
                    setIsOpenMarketInput(true)
                }else{
                    setIsOpenMarketInput(false)
                }
                break
            case 'selectOption':
                if (event?.target?.value) {
                    setSelectOption(event?.target?.value)
                } else {
                    setSelectOptionErr('Required Field')
                }
                break
            case 'priceRange':
                if (event?.target?.value) {
                    setPriceRange(event?.target?.value)
                } else {
                    setPriceRangeErr('Required Field')
                }
                break
            case 'market_price':
                if (event?.target?.value) {
                    setMarketPrice(event?.target?.value)
                } else {
                    setMarketPriceErr('Required Field')
                }
                break
            case 'qunatity':
                if (event.target.value > eventDetails?.oUserConfig?.nMaxBuyQty || event.target.value < eventDetails?.oUserConfig?.nMinBuyQty) {
                    setQuantityErr('Quantity Must be in Range')
                } else if (event?.target?.value) {
                    setQuantity(event?.target?.value)
                    setQuantityErr('')
                } else {
                    setQuantityErr('Required Field')
                }
                break
            default:
                break
        }
    }
    function onSubmit(e) {
        let verify = false
        verify = (priceMarket  && selectOption && quantity)
        verify =  (isOpenMarketInput && marketPrice ? true : !isOpenMarketInput && priceRange ? true :false)
        if (verify) {
          if (isCreate) {
              const ordersData = [
                  {
                      eOrderType: priceMarket,
                      nPrice: isOpenMarketInput ? marketPrice : priceRange,
                      sSymbol: selectOption,
                      nQty: quantity
                  }
              ]
              if(orderId){
                ordersData[0].iBuyOrderId = orderDetails.iOrderId;
              }
              const addOrderData = {
                  bIsSellOrder: orderId ? false : true,
                  orders: ordersData,
                  eventId
              }
            dispatch(sellOrder(addOrderData))
          } 
          setLoading(true)
        }
    }
      useImperativeHandle(ref, () => ({
        onSubmit
      }))
    return (
        <>
            <main className="main-content">
                <AlertMessage
                    close={close}
                    message={message}
                    modalMessage={modalMessage}
                    status={status}
                />
                {loading && <Loading />}
                <section className="common-form-block">
                    <Form>
                        <Row className="mb-4 d-flex">
                            <Col md={12} xl={12}>
                                <h2 className="mb-2">{eventDetails?.sName}</h2>
                                <span>{eventDetails?.sDescription}</span>
                                <Row>
                                <Col  md={8}></Col>
                                <Col md={4} className="text-end">
                                <span className="bold-text" style={{color:'#007bff'}}>Yes : {eventDetails?.aAnswerOptions[0]?.nCurrentPrice} {currencySymbol?.Indian} </span>
                                {/* <br /> */}
                                <span className="bold-text" style={{color:'red'}}>No : {eventDetails?.aAnswerOptions[1]?.nCurrentPrice} {currencySymbol?.Indian} </span>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xl={6}>
                                <FormGroup className='form-group mt-2'>
                                    <Label className='edit-label-setting' for="place">
                                        Price Market
                                        {' '}
                                        <RequiredField />
                                    </Label>
                                    <Input
                                        className={priceMarketErr ? 'league-placeholder-error' : 'form-control'}
                                        disabled={true}
                                        name="priceMarket"
                                        onChange={event => handleChange(event, 'priceMarket')}
                                        type="select"
                                        value={priceMarket}
                                    >
                                        <option value=''>All</option>
                                        <option value='LIMIT'>Limit</option>
                                        {/* <option value='MARKET'>Market</option> */}
                                    </Input>
                                    <p className="error-text">{priceMarketErr}</p>
                                </FormGroup>
                            </Col>
                            <Col md={12} xl={6}>
                                <FormGroup className='form-group mt-2'>
                                    <Label className='edit-label-setting' for="place">
                                        Option
                                        {' '}
                                        <RequiredField />
                                    </Label>
                                    <Input
                                        className={selectOptionErr ? 'league-placeholder-error' : 'form-control'}
                                        disabled={adminPermission?.OT_EVENT === 'R'}
                                        name="option"
                                        onChange={event => handleChange(event, 'selectOption')}
                                        type="select"
                                        value={selectOption}
                                    >
                                        <option value=''>All</option>
                                        <option value='YES'>{eventDetails?.aAnswerOptions[0]?.sLabel }</option>
                                        <option value='NO'>{eventDetails?.aAnswerOptions[1]?.sLabel }</option>
                                    </Input>
                                    <p className="error-text">{selectOptionErr}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {isOpenMarketInput ?
                                <Col md={1} xl={6}>
                                    <FormGroup className='form-group mt-2'>
                                        <Label className='edit-label-setting' for="place">
                                            Price
                                            {' '}
                                            <RequiredField />
                                        </Label>
                                        <Input
                                            className={marketPriceErr ? 'league-placeholder-error' : 'form-control'}
                                            disabled={adminPermission?.OT_EVENT === 'R'}
                                            name="marketPrice"
                                            onChange={event => handleChange(event, 'market_price')}
                                            type="number"
                                            defaultValue={marketPrice}
                                            min={1}
                                        />
                                        <p className="error-text">{marketPriceErr}</p>
                                    </FormGroup>
                                </Col>
                                :
                                <Col md={12} xl={6}>
                                    <FormGroup className='form-group mt-2'>
                                        <Row >
                                            <Col md={12} xl={6} className="ps-0">
                                                <Label className='edit-label-setting' for="place">
                                                    Price :
                                                    {' '}
                                                    <RequiredField />
                                                </Label>
                                            </Col>
                                            <Col md={12} xl={6} className="text-end fw-bold">
                                                {currencySymbol?.Indian} {priceRange}
                                            </Col>
                                        </Row>
                                        <Input
                                            className={priceRangeErr ? 'league-placeholder-error' : ''}
                                            disabled={adminPermission?.OT_EVENT === 'R'}
                                            name="option"
                                            onChange={event => handleChange(event, 'priceRange')}
                                            type="range"
                                            defaultValue={priceRange}
                                            min="0.5"
                                            max={9.5}
                                            step={eventDetails?.nPriceDiff || 1}
                                        />
                                        <p className="error-text">{priceRangeErr}</p>
                                    </FormGroup>
                                </Col>
                            }
                           <Col md={1} xl={6}>
                               <FormGroup className='form-group mt-2'>
                                   <Label className='edit-label-setting' for="place">
                                       Quantity ({eventDetails?.oUserConfig?.nMinBuyQty || 1} - {eventDetails?.oUserConfig?.nMaxBuyQty || 100})
                                       {' '}
                                       <RequiredField />
                                   </Label>
                                   <Input
                                       className={quantityErr ? 'league-placeholder-error' : 'form-control'}
                                       disabled={adminPermission?.OT_EVENT === 'R'}
                                       name="qunatity"
                                       onChange={event => handleChange(event, 'qunatity')}
                                       type="number"
                                    //    value={quantity}
                                       defaultValue={quantity}
                                       min={eventDetails?.oUserConfig?.nMinBuyQty || 1}
                                       max={eventDetails?.oUserConfig?.nMaxBuyQty || 100}
                                   />
                                   <p className="error-text">{quantityErr}</p>
                               </FormGroup>
                           </Col>
                       </Row>
                     
                    </Form>
                    <div className="mt-3">
                        <OrderBook
                            orderBookList={newOrderBookList}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                    {/* <OrderBook eventId={eventId} /> */}
                    </div>
                </section>
            </main>
        </>
    )
})
export default SellOrderForm