import axios from '../axios'
import { catchFunc } from '../helpers/helper'
import { ADD_USER_XP, BANNER_DETAILS, BANNER_LIST, BANNER_STATISTICS, CLEAR_BANNER_MESSAGE, CLEAR_USER_XP_MESSAGE, CLEAR_USERS_MESSAGE, DELETE_BANNER, UPDATE_USER_XP } from './constants'

const errMsg = 'Server is unavailable.'

export const addUserXP = (addUserXPData) => async (dispatch) => {
  const {  Name,minXP,Description,dailyWithdrawalCount,dailyWithdrawalLimit,commission,commissionFeeType,hexColor,xpImage } = addUserXPData
  dispatch({ type: CLEAR_USER_XP_MESSAGE })
  try {
    if(xpImage?.file){
      const response = await axios.post('/statics/admin/banner/pre-signed-url/v1', { sFileName: xpImage?.file?.name, sContentType: xpImage?.file?.type })
      const url = response.data.data.sUrl
      const sImage = response.data.data.sPath
      await axios.put(url, xpImage.file, { headers: { 'Content-Type': xpImage.file.type, noAuth: true } })
      await axios.post('/ot/admin/profile-level/v1', {
        sName:Name ,
        sDescription: Description, 
        sHexCode: hexColor,
        oCriteria:{
          nMinXP:minXP
        },
        oRules:{
          nDailyWithdrawLimit:dailyWithdrawalLimit,
          nDailyWithdrawCount:dailyWithdrawalCount,
          nCommission:commission,
          eCommissionFeeType:commissionFeeType
        },
        sImage:sImage
      }).then((response2) => {
        dispatch({
          type: ADD_USER_XP,
          payload: {
            resMessage: response2.data.message,
            resStatus: true
          }
        })
      })
    }else{
      await axios.post('/ot/admin/profile-level/v1', {
        sName:Name ,
        sDescription: Description, 
        sHexCode: hexColor,
        oCriteria:{
          nMinXP:minXP
        },
        oRules:{
          nDailyWithdrawLimit:dailyWithdrawalLimit,
          nDailyWithdrawCount:dailyWithdrawalCount,
          nCommission:commission,
          eCommissionFeeType:commissionFeeType
        },
      }).then((response2) => {
        dispatch({
          type: ADD_USER_XP,
          payload: {
            resMessage: response2.data.message,
            resStatus: true
          }
        })
      })
    }
  
  } catch (error) {
    dispatch({
      type: ADD_USER_XP,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  }
}
export const deleteBanner = ({ Id, sportsType }) => async (dispatch) => {
  dispatch({ type: CLEAR_BANNER_MESSAGE })
  await axios.delete(`/statics/admin/banner/${Id}/v1`, { params: { sportsType } }).then((response) => {
    dispatch({
      type: DELETE_BANNER,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: DELETE_BANNER,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const updateUserXP = (updateXPData) => async (dispatch) => {
  const {    Name,minXP,Description,dailyWithdrawalCount,dailyWithdrawalLimit,commission,commissionFeeType,hexColor,id,xpImage} = updateXPData
  dispatch({ type: CLEAR_USER_XP_MESSAGE })
  try {
    if (xpImage && xpImage.file) {
      const response = await axios.post('/statics/admin/banner/pre-signed-url/v1', { sFileName: xpImage.file.name, sContentType: xpImage.file.type })
      const url = response.data.data.sUrl
      const sImage = response.data.data.sPath
      await axios.put(url, xpImage.file, { headers: { 'Content-Type': xpImage.file.type, noAuth: true } })
      await axios.put(`/ot/admin/profile-level/${id}/v1`, {
        sName: Name,
        sDescription: Description,
        sImage: sImage,
        oCriteria: {
         nMinXP:minXP
        },
        oRules: {
         nDailyWithdrawLimit:dailyWithdrawalLimit,
         nDailyWithdrawCount:dailyWithdrawalCount,
         nCommission:commission,
         eCommissionFeeType:commissionFeeType
        },
        sHexCode: hexColor
      }).then((response2) => {
        dispatch({
          type: UPDATE_USER_XP,
          payload: {
            resMessage: response2.data.message,
            resStatus: true
          }
        })
      })
    } else {
      await axios.put(`/ot/admin/profile-level/${id}/v1`, {
        sName: Name,
         sDescription: Description,
         sImage: xpImage,
         oCriteria: {
          nMinXP:minXP
         },
         oRules: {
          nDailyWithdrawLimit:dailyWithdrawalLimit,
          nDailyWithdrawCount:dailyWithdrawalCount,
          nCommission:commission,
          eCommissionFeeType:commissionFeeType
         },
         sHexCode: hexColor
      }).then((response) => {
        dispatch({
          type: UPDATE_USER_XP,
          payload: {
            resMessage: response.data.message,
            resStatus: true
          }
        })
      })
    }
  } catch (error) {
    dispatch(catchFunc(UPDATE_USER_XP, error))
  }
}