import axios from '../axios'
import { catchFunc, successFunc } from '../helpers/helper'
import {
  CATEGORY_LIST,
  CLEAR_CATEGORY_MESSAGE,
  CATEGORY_DETAILS,
  UPDATE_CATEGORY,
} from './constants'
export const getCategoryMainList = (start, limit, search) => async (dispatch) => {
  await axios
    .get(`/ot/admin/category/list/v1?start=${start}&limit=${limit}&search=${search}`)
    .then((response) => {
      // console.log(response,'responseresponse')
      dispatch({
        type: CATEGORY_LIST,
        payload: {
          data: response?.data?.data ? response?.data?.data : [],
          resStatus: true,
          resMessage: response.data.message,
        }
      })
    })
    .catch(() => {
      dispatch({
        type: CATEGORY_LIST,
        payload: {
          data: [],
          resStatus: false
        }
      })
    })
}
export const getCategoryList = (start, limit, token) => async (dispatch) => {
  await axios
    .get(`/ot/admin/category/list/v1?start=${start}&limit=${limit}`, {
      headers: { Authorization: token }
    })
    .then((response) => {
      dispatch({
        type: CATEGORY_LIST,
        payload: {
          data: response.data.data[0] ? response.data.data[0] : [],
          resStatus: true,
          resMessage: response.data.message,
        }
      })
    })
    .catch(() => {
      dispatch({
        type: CATEGORY_LIST,
        payload: {
          data: [],
          resStatus: false
        }
      })
    })
}

export const getCategoryDetails = (id, token) => async (dispatch) => {
  await axios
    .get(`/ot/admin/category/${id}/v1`, { headers: { Authorization: token } })
    .then((response) => {
      dispatch({
        type: CATEGORY_DETAILS,
        payload: {
          data: response.data.data,
          resStatus: true,
          resMessage: response.data.message,
        }
      })
    })
    .catch(() => {
      dispatch({
        type: CATEGORY_DETAILS,
        payload: {
          data: [],
          resStatus: false
        }
      })
    })
}

export const updateCategoory = (updateCategoryData) => async (dispatch) => {
  const { categoryId, name, description, type, image, token,activeStatus,priority } = updateCategoryData
  dispatch({ type: CLEAR_CATEGORY_MESSAGE })
  try {
    if (image && image.file) {
      const response = await axios.post('/common/get-presigned-url/v1', { sFileName: image.file.name, sContentType: image.file.type,sFolderName:'category' })
      const url = response.data.data.sUrl
      const sImage = response.data.data.sPath
      await axios.put(url, image.file, { headers: { 'Content-Type': image.file.type, noAuth: true } })
      await axios.put( `/ot/admin/category/${categoryId}/v1`, {
        sName: name,
        sDescription: description,
        eCategoryType: type,
        sImage:sImage,
        eStatus:activeStatus,
        nPriority:priority
      }).then((response2) => {
        dispatch({
          type: UPDATE_CATEGORY,
          payload: {
            resMessage: response2.data.message,
            resStatus: true
          }
        })
      })
    } else {
      const url = image;
      const path = new URL(url).pathname;
      const updatedPath = path.startsWith('/') ? path.substring(1) : path;
      await axios
      .put(
        `/ot/admin/category/${categoryId}/v1`,
        {
          sName: name,
          sDescription: description,
          eCategoryType: type,
          sImage:updatedPath,
          eStatus:activeStatus,
          nPriority: priority
        },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        dispatch(successFunc(UPDATE_CATEGORY, response))
      })
      .catch((error) => {
        dispatch(catchFunc(UPDATE_CATEGORY, error))
      })
    }
  } catch (error) {
    dispatch(catchFunc(UPDATE_CATEGORY, error))
  }
 
}
