import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, FormGroup, Input } from "reactstrap"
import calendarIcon from '../../../assets/images/calendar.svg'
import addlIcon from '../../../assets/images/add-white-icon.svg'
import closeIcon from '../../../assets/images/close-icon.svg'
import { Link } from "react-router-dom";

const OrderListHeader = (props) => {
    const { dateRange, endDate, userId, handleOtherFilter, handleSearch, list, buttonTextSell, search, buttonText,totalCount,
         setDateRange, startDate, bIsAdminOrders, eventOrderType, orders, dateFlag,orderStatus,searchType,eventBidType,eventOrderStatus } = props
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='form-control date-range' onClick={onClick}>
            <img alt="calendar" src={calendarIcon} />
            <Input ref={ref} className='mx-2 range' placeholder='Select Date' readOnly value={value} />
        </div>
    ))
    ExampleCustomInput.displayName = ExampleCustomInput
    return (
        <div className="header-block">
            <div className="filter-block d-flex justify-content-between align-items-start fdc-480">
                <Form className="d-flex flex-wrap fdc-480 user-sub-header">
                    {orders &&
                        <FormGroup className='form-group'>
                            <Input
                                className="select-user-header w-100"
                                id="type"
                                name="type"
                                onChange={handleOtherFilter}
                                type="select"
                                value={searchType}
                            >
                                <option value=''>Search by</option>
                                <option key='USERNAME' value='USERNAME'>User Name</option>
                                {/* <option key='EVENTNAME' value='EVENTNAME'>Event Name</option>
                                <option key='SUBCATEGORYNAME' value='SUBCATEGORYNAME'>Sub Category</option>
                                <option key='CATEGORYNAME' value='CATEGORYNAME'>Category</option> */}
                            </Input>
                        </FormGroup>
                    }
                    <FormGroup className='form-group'>
                        <Input className="search-box" name="search" onChange={handleSearch} autoComplete='off' onKeyPress={handleSearch} placeholder="Search" type="search" value={search} />
                    </FormGroup>
                    <FormGroup className='form-group'>
                        <DatePicker
                            customInput={<ExampleCustomInput />}
                            dropdownMode="select"
                            endDate={endDate}
                            isClearable={true}
                            maxDate={new Date()}
                            onChange={(update) => { setDateRange(update); dateFlag && (dateFlag.current = true) }}
                            peekNextMonth
                            placeholderText='Select Date Range'
                            selectsRange={true}
                            showMonthDropdown
                            showYearDropdown
                            startDate={startDate}
                            value={dateRange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            className='format-f'
                            id="eBidType"
                            name="eBidType"
                            onChange={(e) => handleOtherFilter(e, 'eBidType')}
                            type="select"
                            value={eventBidType}
                        >
                            <option value="">Bid Type</option>
                            {orderStatus?.eBidType.map((status, index) => (
                                <option key={index} value={status}>
                                    {status}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            className='format-f'
                            id="eOrderType"
                            name="eOrderType"
                            onChange={(e) => handleOtherFilter(e, 'eOrderType')}
                            type="select"
                            value={eventOrderType}
                        >
                            <option value="">Order Type</option>
                            {orderStatus?.eOrderType.map((status, index) => (
                                <option key={index} value={status}>
                                    {status}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            className='format-f'
                            id="eStatus"
                            name="eStatus"
                            onChange={(e) => handleOtherFilter(e, 'seOrderStatus')}
                            type="select"
                            value={eventOrderStatus}
                        >
                            <option value="">Status</option>
                            {orderStatus?.eOrderStatus.map((status, index) => (
                                <option key={index} value={status}>
                                    {status}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    {userId &&
                        <FormGroup>
                            <Input
                                className='format-f'
                                id="bIsAdminOrders"
                                name="bIsAdminOrders"
                                onChange={(e) => handleOtherFilter(e, 'bIsAdminOrders')}
                                type="select"
                                value={bIsAdminOrders}
                            >
                                <option value="">Select Order</option>
                                <option value="admin">Admin Orders</option>
                                <option value="user">User Orders</option>
                            </Input>
                        </FormGroup>
                    }
                </Form>
                {totalCount && (
                    <div className='total-text-count'>
                        Total Orders :
                        {/* <b>{(props?.heading === true ? list?.count : props?.totalCount?.count) || props?.totalCount}</b> */}
                        <b>{(list?.length ? totalCount : 0)}</b>
                        {!orders &&
                            <Button className='theme-btn mx-2'>
                                Manage Columns
                            </Button>}
                    </div>
                )}
                <FormGroup className='d-flex'>
                {(buttonTextSell) && (
                        <Button className={`theme-btn icon-btn`} tag={Link} to={props?.setUrlSell}>
                            <img alt="add" src={addlIcon} />
                            {buttonTextSell}
                        </Button>
                    )}
                    {(buttonText) && (
                        <Button className={`theme-btn icon-btn ms-1`} tag={Link} to={props?.setUrl}>
                            <img alt="add" src={addlIcon} />
                            {buttonText}
                        </Button>
                    )}
                </FormGroup>
            </div>
        </div>
    )
}
export default OrderListHeader