import React, { useContext, useEffect, useState } from 'react';
import ChatSidebar from './ChatSidebar';
import getThreadList from '../../api/SupportChat/getThreadList';
import { useQueryState } from 'react-router-use-location-state';
import { useQuery } from '@tanstack/react-query';
import ChatBar from './ChatBar';
import SocketContext from '../../context/SocketContext';

const ChatSupport = () => {
  const [selectedThread, setSelectedThread] = useState(null);
  const [start, setStart] = useState(0)
  const [search, setSearch] = useQueryState('search', '')
  const [offset, setOffset] = useQueryState('pageSize', 10)

  const { data: threadList, isLoading } = useQuery({
    queryKey: ['getThreadList', start, offset, search],
    queryFn: () => getThreadList(start, offset, search),
    select: (response) => response?.data?.data?.results
  })

  return (
    <div className="chat-app">
      <ChatSidebar onSelectThread={setSelectedThread}
        threadList={threadList}
        isLoading={isLoading}
      />
      <div className="chat-window">
        {selectedThread && (
          <ChatBar selectedThread = {selectedThread} />
        )}
      </div>
    </div>
  );
};

export default ChatSupport;
