import React, { useEffect, useState } from 'react'
import { Row, Col, Input } from 'reactstrap'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
// import liveMatch from '../../../assets/images/live-match.svg'

function SportDetails ({ dashBoardData }) {
  const [dayFilter, setDayFilter] = useState('month')
  const [categoryName, setCategoryName] = useState('C')
  const [categoryId, setCategoryId] = useState('')
  const [categoryDetails, setCategoryDetails] = useState('')
  const navigate = useNavigate()
  function onFiltering (event) {
    setDayFilter(event.target.value)
  }
  function setCategoryIdFun (name,id) {
    setCategoryName(name)
    setCategoryId(id)
  }
  useEffect(() => {
    if (dashBoardData) {
      const categoryInfo = dashBoardData?.aEventData?.find((data) => data?.eCategory === categoryName)
      setCategoryDetails(categoryInfo)
      setCategoryName(categoryInfo?.eCategory)
      setCategoryId(categoryInfo?.iCategoryId)
    }
  }, [categoryId, dashBoardData])
  const handleOnClickEvent = () =>{
    const currentDate = new Date();
    let firstDay=''
    let lastDay=''
    if(dayFilter==='month'){
      firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      firstDay.setHours(0, 0, 0, 0);
      lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      lastDay.setHours(23, 59, 59, 999);
    }else if(dayFilter==='week'){
      const dayOfWeek = currentDate.getDay(); 
      firstDay = new Date(currentDate);
      firstDay.setDate(currentDate.getDate() - dayOfWeek); 
      firstDay.setHours(0, 0, 0, 0); 
      lastDay = new Date(currentDate);
      lastDay.setDate(currentDate.getDate() + (6 - dayOfWeek)); 
      lastDay.setHours(23, 59, 59, 999); 
    }else{
      firstDay = new Date(currentDate);
      firstDay.setHours(0, 0, 0, 0);
      lastDay = new Date(currentDate);
      lastDay.setHours(23, 59, 59, 999);
    }
    navigate(`/events/event-management?dStartDate=${firstDay}&dEndDate=${lastDay}&iCategory=${categoryId}`)
  }
  return (
    <>
      <Row className='sport-details'>
        <div className='sport-details-header'>
          <h3>Event wise Details</h3>
          <Input
            className='day-filter'
            id="dayFilter"
            name="dayFilter"
            onChange={(event) => onFiltering(event)}
            type="select"
            value={dayFilter}
          >
            <option value="today">Today</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </Input>
        </div>
        <div className='sport-details-sport-name'>
          {dashBoardData?.aEventData?.map((data) => (
            <>
              <li className={categoryId === data?.iCategoryId ? 'active-sport' : ''} onClick={() => setCategoryIdFun(data?.eCategory ,data?.iCategoryId)}>{data?.eCategory === 'C' ? 'CRICKET' : data?.eCategory ==='CR' ? 'CRYPTO' : data?.eCategory==='S' ? 'STOCK' : 'IPO'}</li>
            </>
          )
          )}
        </div>
        <Col className='sport-details-col' md={12} xl={12}>
          <Row>
            <Col className='live-match-col' md={4} xl={4}>
              <div className='live-match '>
              <button className='w-100 ' onClick={ handleOnClickEvent} style={{border:"none",textAlign:'left',background:'transparent'}}>
                <h2>{(dayFilter === 'today' ? categoryDetails?.oLiveEvents?.nToday : dayFilter === 'month' ? categoryDetails?.oLiveEvents?.nMonth : categoryDetails?.oLiveEvents?.nWeek) || 0}</h2>
                <p>
                  {' '}
                  {/* <img alt="liveMatch" src={liveMatch} /> */}
                  {' '}
                   Events
                </p>
                </button>
              </div>
            </Col>
            {/* <Col className='upcoming-match-col' md={4} xl={4}>
              <div className='upcoming-match'>
                <h2>
                  {dayFilter === 'today' ? sportDetails?.oUpcomingMatches?.nToday : dayFilter === 'month' ? sportDetails?.oUpcomingMatches?.nMonth : sportDetails?.oUpcomingMatches?.nWeek}
                  {' '}
                </h2>
                <p> Upcoming Matches</p>
              </div>
            </Col>
            <Col md={4} xl={4} >
              <div className='upcoming-match'>
                <h2>
                  {' '}
                  {dayFilter === 'today' ? sportDetails?.oCompletedMatches?.nToday : dayFilter === 'month' ? sportDetails?.oCompletedMatches?.nMonth : sportDetails?.oCompletedMatches?.nWeek}
                </h2>
                <p>  Completed Matches</p>
              </div>
            </Col> */}

          </Row>
        </Col>
      </Row>
    </>
  )
}

SportDetails.propTypes = {
  dashBoardData: PropTypes.object
}
export default SportDetails
