import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryState } from 'react-router-use-location-state'
import { Button, Col, FormGroup, Input, Modal, ModalBody, Row } from 'reactstrap'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import qs from 'query-string'

import editButton from '../../../assets/images/edit-pen-icon.svg'

import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'
import DataNotFound from '../../../components/DataNotFound'
import SkeletonTable from '../../../components/SkeletonTable'
import PaginationComponent from '../../../components/PaginationComponent'
import { modalMessageFunc } from '../../../helpers/helper'
import { getUrl } from '../../../actions/url'
import warningIcon from '../../../assets/images/error-warning.svg'
import noImage from '../../../assets/images/no-image-1.svg'
import { getCategoryMainList, updateCategoory } from '../../../actions/category'
import { useQueryClient } from '@tanstack/react-query'

const Category = forwardRef((props, ref) => {
  const {setSearch, categoryList, modalMessage, setModalMessage, message, setMessage, status, setStatus, setOffset, setStart, start, offset, isLoading } = props
  const navigate = useNavigate()
  const location = useLocation()
  const exporter = useRef(null)
  const queryClient = useQueryClient()

  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])
  const [activePageNo, setPageNo] = useQueryState('page', 1)
  const [startingNo, setStartingNo] = useState(0)
  const [endingNo, setEndingNo] = useState(0)
  const [index, setIndex] = useState(1)
  const [listLength, setListLength] = useState('10 Rows')
  const [close, setClose] = useState(false)
  const [url, setUrl] = useState('')
  const getUrlLink = useSelector(state => state?.url?.getUrl)
  const resStatus = useSelector((state) => state?.version?.resStatus)
  const resMessage = useSelector((state) => state?.version?.resMessage)
  const [type, setType] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [loading, setLoading] = useState(false)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)

  const [modalWarning, setModalWarning] = useState(false)
  const toggleWarning = () => setModalWarning(!modalWarning)
  const searchProp = props?.search
  const obj = qs?.parse(location?.search)
  const previousProps = useRef({ categoryList, resMessage, resStatus, start, offset, searchProp })?.current
  const paginationFlag = useRef(false)


  useEffect(() => {
    if (getUrlLink) {
      setUrl(getUrlLink)
    }
  }, [getUrlLink])
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.message) {
        setMessage(location?.state?.message)
        setModalMessage(true)
        setStatus(true)
      }
      navigate(location?.pathname, { replace: true })
    }
    let page = 1
    let limit = offset
    if (obj) {
      if (obj?.page) {
        page = obj?.page
        setPageNo(page)
      }
      if (obj?.pageSize) {
        limit = obj?.pageSize
        setOffset(limit)
        setListLength(`${limit} Rows`)
      }
    }
    const startFrom = (page - 1) * offset
    setStart(startFrom)
  }, [])
  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  useEffect(() => {
    let data = localStorage?.getItem('queryParams')
      ? JSON?.parse(localStorage?.getItem('queryParams'))
      : {}
    !Object?.keys(data)?.length
      ? (data = { ValidationManagement: location?.search })
      : (data.CategoryManagement = location?.search)
    localStorage?.setItem('queryParams', JSON?.stringify(data))
  }, [location?.search])

  // to set categoryList
  useEffect(() => {
    // if (previousProps.versionList !== versionList) {
    if (categoryList) {
      if (categoryList?.results) {
        const userArrLength = categoryList && categoryList?.results?.length
        const startFrom = (activePageNo - 1) * offset + 1
        const end = startFrom - 1 + userArrLength
        setStartingNo(startFrom)
        setEndingNo(end)
      }
      setList(categoryList && categoryList?.results)
      setIndex(activePageNo)
      setTotal(categoryList?.total ? categoryList?.total : 0)
      dispatch(getUrl('media'))
    } else {
      setList([])
    }

    return () => {
      previousProps.categoryList = categoryList
    }
  }, [categoryList])

  // to handle response
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        if (resStatus) {
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
          setPageNo(1)
        } else {
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
        }
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])
  useEffect(() => {
    const callSearchService = () => {
      const startFrom = 0
      const limit = offset
      // getList(startFrom, limit, sort, order, props.search)
      setSearch(searchProp?.trim())
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    if (previousProps?.searchProp !== searchProp && props?.flag) {
      const debouncer = setTimeout(() => {
        callSearchService()
      }, 1000)
      return () => {
        clearTimeout(debouncer)
        previousProps.searchProp = searchProp
      }
    }
    return () => {
      previousProps.searchProp = searchProp
    }
  }, [searchProp])
  useEffect(() => {
    if ((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current) {
      // getList(start, offset, sort, order, search)
      setLoading(true)
    }
    return () => {
      previousProps.start = start
      previousProps.offset = offset
    }
  }, [start, offset])
  // Export Excel Report List
  const processExcelExportData = data => data?.map((categoryList, index) => {
    const srNo = index + 1
    const eStatus = categoryList?.eStatus == "Y" ? 'true' : 'false'
    let dCreatedAt = moment(categoryList?.dCreatedAt)?.local()?.format('lll')
    dCreatedAt = dCreatedAt === 'Invalid date' ? ' - ' : dCreatedAt
    const sDescription = categoryList?.sDescription
    const sName = categoryList?.sName
    const eCategoryType = categoryList?.eCategoryType
    return {
      ...categoryList,
      srNo,
      sName,
      eCategoryType,
      dCreatedAt,
      sDescription,
      eStatus
    }
  })

  function onExport() {
    const { length } = list
    if (length !== 0) {
      exporter.current.props = { ...exporter?.current?.props, data: processExcelExportData(list), fileName: 'Categories.xlsx' }
      exporter?.current?.save()
    }
  }
  function warningWithConfirmMessage(data, eType) {
    setType(eType)
    setSelectedData(data)
    setModalWarning(true)
  }
  // update status from list and dispatch action
  function onStatusUpdate() {
    const statuss = selectedData?.eStatus === 'Y' ? 'N' : 'Y'
    const updatedSubCategory = {
      name: selectedData?.name,
      description: selectedData?.description,
      image: selectedData?.sImage,
      activeStatus: statuss,
      categoryId: selectedData?._id,
    }
    dispatch(updateCategoory(updatedSubCategory))
    setTimeout(() => {
      queryClient?.invalidateQueries('getCategoryList')
      // dispatch(getCategoryMainList(start,offset,''))
    }, 100); 
    setLoading(true)
    toggleWarning()
  }
  useImperativeHandle(ref, () => ({
    onExport
  }))
  return (
    <Fragment>
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <ExcelExport ref={exporter} data={list} fileName="Versions.xlsx">
        <ExcelExportColumn field="srNo" title="Sr No" />
        <ExcelExportColumn field="eStatus" title="Status" />
        <ExcelExportColumn field="sName" title="Name" />
        <ExcelExportColumn field="sDescription" title="Description" />
        <ExcelExportColumn field="eCategoryType" title="Type" />
        <ExcelExportColumn field="dCreatedAt" title="Creation Date" />
      </ExcelExport>

      {isLoading && <Loading />}
      {!isLoading && list?.length === 0
        ? (<DataNotFound message="Category" obj={obj} />)
        : (
          <div className='table-represent'>
            <div className='table-responsive'>
              <table className='content-table'>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    {/* <th>Type</th> */}
                    <th>Status</th>
                    <th>Description</th>
                    <th>Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading
                    ? (<SkeletonTable numberOfColumns={8} />)
                    : (
                      <Fragment>
                        {list && list?.length !== 0 && list.map((data, i) => (
                          <tr key={data?._id}>
                            <td>{(index - 1) * offset + (i + 1)}</td>
                            <td>{data?.sName || '-'}</td>
                            {/* <td>{data?.eCategoryType || '-'}</td> */}
                            <td>
                              <FormGroup switch>
                                <Input
                                  checked={data?.eStatus === 'Y' ? true : false}
                                  disabled={adminPermission?.OT_CATEGORY === 'R'}
                                  id={'id' + data?._id}
                                  name={'name' + data?._id}
                                  onClick={() => warningWithConfirmMessage(data, data?.eStatus === 'Y' ? 'Inactivate' : 'Activate')}
                                  type='switch'
                                />
                              </FormGroup>
                            </td>
                            <td>{data?.sDescription || '-'}</td>
                            <td>{data?.sImage
                              ? <img alt="banner" className="theme-image-offer" src={data?.sImage?.includes('https://') ? data?.sImage : url + data?.sImage} width={56} height={56} />
                              : <img alt="NoImg" className='theme-image-offer' src={noImage} />}
                            </td>
                            <td>
                              <ul className='action-list mb-0 d-flex p-0'>
                                <li>
                                  <Button className='edit-btn-icon' color='link' tag={Link} to={`/category-management/edit-category/${data?._id}`}>
                                    <span><img alt="View" src={editButton} /></span>
                                  </Button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    )}
                </tbody>
              </table>
              <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
                <ModalBody className='text-center'>
                  <img alt='check' className='info-icon' src={warningIcon} />
                  <h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
                  <Row className='row-12'>
                    <Col>
                      <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={toggleWarning} type='submit'>Cancel</Button>
                    </Col>
                    <Col>
                      <Button className='theme-btn danger-btn full-btn' onClick={onStatusUpdate} type='submit'>{`${type} It`}</Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          </div>
        )
      }

      {list?.length !== 0 && (
        <PaginationComponent
          activePageNo={activePageNo}
          endingNo={endingNo}
          listLength={listLength}
          offset={offset}
          paginationFlag={paginationFlag}
          setListLength={setListLength}
          setOffset={setOffset}
          setPageNo={setPageNo}
          setStart={setStart}
          startingNo={startingNo}
          total={total}
        />
      )}
    </Fragment>
  )
})

Category.propTypes = {
}

Category.displayName = Category

export default Category
