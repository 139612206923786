import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import infoIcon from '../../../assets/images/info-icon.svg'
import refreshIcon from '../../../assets/images/refresh-icon-1.svg'

import DataNotFound from '../../../components/DataNotFound'
import { updateApplicableTaxReport } from '../../../actions/reports'

import Info from '../../../helpers/info'
import { dateFormate, fixDigit } from '../../../helpers/helper'

function ApplicableTaxReport (props) {
  const { dateWiseReports, permission, isOpen, token, userType, sports, applicableTaxReport, setLoading, previousProps, setApplicableTaxReport } = props
  const dispatch = useDispatch()
  const updatedApplicableTaxData = useSelector((state) => state?.reports?.updatedApplicableTaxData)
  useEffect(() => {
    if (updatedApplicableTaxData) {
      if (previousProps?.updatedApplicableTaxData !== updatedApplicableTaxData) {
        setApplicableTaxReport(updatedApplicableTaxData?.aCategoryTax)
      }
    }
    return () => {
      previousProps.updatedApplicableTaxData = updatedApplicableTaxData
    }
  }, [updatedApplicableTaxData])

  function updateApplicableTaxReportFunc (id, transactionType) {
    if (token) {
      const updateData = {
        id, transactionType, userType, token
      }
      dispatch(updateApplicableTaxReport(updateData))
      setLoading(true)
    }
  }

  return (
    <>
      {isOpen === 'TAX_REPORT' && dateWiseReports?.length !== 0 && (
      <section className='user-section'>
        <div className='table-represent-two'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr align='center'>
                  <th>
                    Transaction Type
                    <img className='custom-info' id='PLAY_TAX_REPORT' src={infoIcon} />
                    <UncontrolledTooltip className='bg-default-s' delay={0} placement='bottom' target='PLAY_TAX_REPORT'>
                      <p>{Info?.playReturn}</p>
                    </UncontrolledTooltip>
                  </th>
                  <th>Total</th>
                  <th >Last Update</th>
                </tr>
              </thead>
              <tbody>
                {dateWiseReports?.aCategoryTax && dateWiseReports?.aCategoryTax?.map((data, index) => (
                  <tr key={index} align='center'>
                    <td><b>{data?.eTransactionType}</b></td>
                    <td>{fixDigit(data?.nTotal)}</td>
                    <td>{dateFormate(data?.dUpdatedAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      )}
      {isOpen === 'TAX_REPORT' && dateWiseReports?.length === 0
        ? (applicableTaxReport && applicableTaxReport?.length > 0)
            ? (
              <section className='user-section'>
                <div className='table-represent-two'>
                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr align='center'>
                          <th>Transaction Type</th>
                          <th>Total</th>
                          <th>Today</th>
                          <th>Yesterday</th>
                          <th>Last Week</th>
                          <th>Last Month</th>
                          <th>Last Year</th>
                          <th>Last Update</th>
                          <th>
                            <img className='custom-info' id='PLAYRETURN' src={infoIcon} />
                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='bottom' target='PLAYRETURN'>
                              <p>{Info?.playReturn}</p>
                            </UncontrolledTooltip>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicableTaxReport?.map((data, index) => (
                          <tr key={index} align="center">
                            <td><b>{data?.eTransactionType}</b></td>
                            <td>{fixDigit(data?.nTotal)}</td>
                            <td>{fixDigit(data?.nToday)}</td>
                            <td>{fixDigit(data?.nYesterday)}</td>
                            <td>{fixDigit(data?.nLastWeek)}</td>
                            <td>{fixDigit(data?.nLastMonth)}</td>
                            <td>{fixDigit(data?.nLastYear)}</td>
                            <td>{dateFormate(data?.dUpdatedAt)}</td>
                            <td>
                              {permission && (
                              <Button color='link' onClick={() => updateApplicableTaxReportFunc(data?._id, data?.eTransactionType)}>
                                <img alt='Applicabletax' height='20px' src={refreshIcon} width='20px'/>
                              </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              )
            : <DataNotFound message='Data' obj=''/>
        : ''
      }
    </>
  )
}
ApplicableTaxReport.propTypes = {
  dateWiseReports: PropTypes.string,
  isOpen: PropTypes.bool,
  sports: PropTypes.array,
  applicableTaxReport: PropTypes.array,
  setApplicableTaxReport: PropTypes.func,
  permission: PropTypes.string,
  userType: PropTypes.string,
  token: PropTypes.string,
  setLoading: PropTypes.bool,
  previousProps: PropTypes?.object
}

export default ApplicableTaxReport
