/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Badge, Button } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { useQueryState } from 'react-router-use-location-state'
import qs from 'query-string'
import moment from 'moment'
import PropTypes from 'prop-types'

import sortIcon from '../../../assets/images/sort-icon.svg'

import SkeletonTable from '../../../components/SkeletonTable'
import PaginationComponent from '../../../components/PaginationComponent'
import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'
import DataNotFound from '../../../components/DataNotFound'
import { modalMessageFunc } from '../../../helpers/helper'

const PassbookList = forwardRef((props, ref) => {
  const exporter = useRef(null)
  const {
    getList, List, startDate, endDate, flag, getTransactionsTotalCountFunc, searchType, isLeaguePassbook, leaguePassbookList, leagueTransactionsTotalCountFunc, userType, columnNames, getGameCategory,
    SportsType, match, matchList, listOfMatches, setListOfMatches, matchInput, matchListing, setSelectedMatch, getMatch, getMatchesTotalCountFunc, searchValue, setMatchTotal, eType, particulars
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const searchProp = props?.search
  const obj = qs?.parse(location?.search)
  const paginationFlag = useRef(false)

  const [isFullResponse] = useState(false)
  const [fullList, setFullList] = useState([])
  const [start, setStart] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [searchTypeBy, setSearchTypeBy] = useQueryState('searchType', '')
  const [transactionStatus] = useQueryState('transactionStatus', '')
  const [startingNo, setStartingNo] = useState(0)
  const [endingNo, setEndingNo] = useState(0)
  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])
  const [nameOrder, setNameOrder] = useState('asc')
  const [createdOrder, setCreatedOrder] = useState('asc')
  const [activePageNo, setPageNo] = useQueryState('page', 1)
  const [order, setOrder] = useQueryState('order', 'desc')
  const [offset, setOffset] = useQueryState('pageSize', 10)
  // eslint-disable-next-line no-unused-vars
  const [dateFrom, setDateFrom] = useQueryState('datefrom', '')
  // eslint-disable-next-line no-unused-vars
  const [dateTo, setDateTo] = useQueryState('dateto', '')
  const [sort, setSort] = useQueryState('sortBy', 'id')
  const [search, setSearch] = useQueryState('searchValue', '')
  const [listLength, setListLength] = useState('10 Rows')
  const [close, setClose] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)

  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const transactionsTotalCount = useSelector(state => state?.passbook?.transactionsTotalCount)
  const isFullList = useSelector(state => state?.passbook?.isFullResponse)
  const resStatus = useSelector(state => state?.passbook?.resStatus)
  const resMessage = useSelector(state => state?.passbook?.resMessage)
  const matchDetails = useSelector(state => state?.match?.matchDetails)
  const matchesTotalCount = useSelector(state => state.match.matchesTotalCount)

  const previousProps = useRef({
    resStatus, resMessage, List, startDate, endDate, particulars, eType, start, offset, transactionsTotalCount, transactionStatus, searchType, searchProp, userType, SportsType, match, matchDetails
  })?.current

  // handle call when initial query params change
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.message) {
        setMessage(location?.state?.message)
        setStatus(true)
        setModalMessage(true)
      }
      navigate(location?.pathname, { replace: true })
    }
    let page = 1
    let limit = offset
    if (obj) {
      if (obj?.page) {
        page = obj?.page
        setPageNo(page)
      }
      if (obj?.pageSize) {
        limit = obj?.pageSize
        setOffset(limit)
        setListLength(`${limit} Rows`)
      }
    }
    if (obj?.iSeasonId) {
      getMatch(obj?.iSeasonId)
    }
    const startFrom = (page - 1) * offset
    setStart(startFrom)
    if (props?.location?.state?.passbookId) {
      setSearch(location.state.passbookId)
    }
    if (props?.location?.state?.systemUserToPassbook) {
      setSearch(location?.state?.id)
    }
    if (adminPermission?.MATCH !== 'N') {
      getGameCategory()
    }
    if (location?.state && location?.state?.userId) {
      setSearch(location?.state?.userId)
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, location?.state?.userId, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(location?.state?.userId, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, location.state.userId, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(location?.state?.userId, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
    } else if (!(obj?.datefrom && obj?.dateto)) {
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
    }
    setLoading(true)
    setListOfMatches([])
    // matchListing(0, 10, '', SportsType)
  }, [])

  useEffect(() => {
    if (props?.isUserToPassbook) {
      setSearch(props?.userToPassbookId)
    }
  }, [props?.userToPassbookId])

  // will be called when something searched
  useEffect(() => {
    const callSearchService = () => {
      const startFrom = 0
      const limit = offset
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, props?.search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(props?.search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, props?.search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(props?.search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setSearch(searchProp?.trim())
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    if (previousProps?.searchProp !== searchProp && flag) {
      if (!props?.isTdsToPassbook) {
        const deBouncer = setTimeout(() => {
          callSearchService()
        }, 1000)
        return () => {
          clearTimeout(deBouncer)
          previousProps.searchProp = searchProp
        }
      }
    }
    return () => {
      previousProps.searchProp = searchProp
    }
  }, [searchProp])

  useEffect(() => {
    if (previousProps.match !== match) {
      const startFrom = 0
      const limit = offset
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setPageNo(1)
      setLoading(true)
    }
    return () => {
      previousProps.match = match
    }
  }, [match])

  useEffect(() => {
    if (previousProps?.matchList !== matchList) {
      if (matchList?.results?.length > 0) {
        const arr = [...listOfMatches]
        matchList.results.map((match) => {
          const obj = {
            value: match?._id,
            label: match?.sName + '(' + moment(match?.dStartDate).format('DD/MM/YYYY hh:mm:ss A') + ')'
          }
          arr.push(obj)
          return arr
        })
        setListOfMatches(arr)
      }
      setLoading(false)
    }
    if (previousProps.matchesTotalCount !== matchesTotalCount) {
      setMatchTotal(matchesTotalCount?.count ? matchesTotalCount.count : 0)
      setLoading(false)
    }
    return () => {
      previousProps.matchList = matchList
      previousProps.matchesTotalCount = matchesTotalCount
    }
  }, [matchList, matchesTotalCount])

  useEffect(() => {
    if (previousProps?.matchDetails !== matchDetails) {
      setSelectedMatch({
        value: matchDetails?._id,
        label: matchDetails?.sName
      })
    }

    return () => {
      previousProps.matchDetails = matchDetails
    }
  }, [matchDetails])

  useEffect(() => {
    setListOfMatches([])
    const callSearchService = () => {
      matchListing(0, 10, matchInput, SportsType)
      getMatchesTotalCountFunc(searchValue, SportsType)
    }
    if (matchInput) {
      if (previousProps?.matchInput !== matchInput) {
        const debouncer = setTimeout(() => {
          callSearchService()
        }, 1000)
        return () => {
          clearTimeout(debouncer)
          previousProps.matchInput = matchInput
        }
      }
    }
    // if (!matchInput) {
    //   matchListing(0, 10, matchInput, SportsType)
    //   getMatchesTotalCountFunc(searchValue, SportsType)
    // }
    return () => {
      previousProps.matchInput = matchInput
    }
  }, [matchInput])

  // handle to set SearchType
  useEffect(() => {
    if (previousProps?.searchType !== searchType) {
      if (searchType === 'NAME' || searchType === 'USERNAME' || searchType === 'MOBILE' || searchType === 'PASSBOOK' || searchType === '') {
        if (!props?.isTdsToPassbook) {
          const startFrom = 0
          const limit = offset
          if (isLeaguePassbook) {
            leaguePassbookList(startFrom, limit, sort, order, search, props.searchType, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
            leagueTransactionsTotalCountFunc(search, props.searchType, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
          } else {
            getList(startFrom, limit, sort, order, userType, search, props.searchType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
            getTransactionsTotalCountFunc(search, props.searchType, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
          }
          setPageNo(1)
          setStart(startFrom)
          setLoading(true)
        }
        setSearchTypeBy(props?.searchType)
      }
    }
    return () => {
      previousProps.searchType = searchType
    }
  }, [searchType])

  useEffect(() => {
    if (previousProps?.userType !== userType) {
      const startFrom = 0
      const limit = offset
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, search, props?.searchType, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, props.searchType, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, search, props?.searchType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(search, props.searchType, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setPageNo(1)
      setLoading(true)
    }
    return () => {
      previousProps.userType = userType
    }
  }, [userType])

  // use effect to startDate and EndDate Change
  useEffect(() => {
    const shouldUpdate = previousProps?.startDate !== startDate || previousProps?.endDate !== endDate
    if (shouldUpdate) {
      const hasValidDates = props?.startDate && props?.endDate
      const startFrom = (obj?.datefrom && obj?.dateto && obj?.page) ? (obj?.page - 1) * offset : 0
      const limit = offset
      const fetchData = () => {
        const commonParams = [startFrom, limit, sort, order, search, searchTypeBy, props?.startDate, props?.endDate, particulars, eType, transactionStatus, isFullResponse]
        const listFunction = isLeaguePassbook ? leaguePassbookList : getList
        const countFunction = isLeaguePassbook ? leagueTransactionsTotalCountFunc : getTransactionsTotalCountFunc
        listFunction(...commonParams, userType, SportsType, match)
        countFunction(search, searchTypeBy, userType, props.startDate, props?.endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      if (hasValidDates) {
        fetchData()
        setDateFrom(new Date(moment(startDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))
        setDateTo(new Date(moment(endDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))
        setPageNo(obj?.page || 1)
      } else {
        fetchData()
        setDateFrom('')
        setDateTo('')
        setPageNo(1)
      }
      setLoading(true)
    }
    return () => {
      previousProps.startDate = startDate
      previousProps.endDate = endDate
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (previousProps?.eType !== eType) {
      const startFrom = 0
      const limit = offset
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setPageNo(1)
    }
    return () => {
      previousProps.eType = eType
    }
  }, [eType])

  // handle set transactionStatus
  useEffect(() => {
    if (previousProps?.transactionStatus !== transactionStatus) {
      const startFrom = 0
      const limit = offset
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setPageNo(1)
    }
    return () => {
      previousProps.transactionStatus = transactionStatus
    }
  }, [transactionStatus])

  // handle set Particulars
  useEffect(() => {
    if (previousProps?.particulars !== particulars) {
      const startFrom = 0
      const limit = offset
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setPageNo(1)
    }
    return () => {
      previousProps.particulars = particulars
    }
  }, [particulars])

  // useEffect to handle QueryParams
  useEffect(() => {
    let data = localStorage?.getItem('queryParams')
      ? JSON?.parse(localStorage?.getItem('queryParams'))
      : {}
    !Object?.keys(data)?.length
      ? (data = { Passbook: location?.search })
      : (data.Passbook = location?.search)
    localStorage?.setItem('queryParams', JSON?.stringify(data))
  }, [location?.search])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // handle set List Details
  useEffect(() => {
    if (previousProps?.List !== List) {
      if (List && !isFullList) {
        const userArrLength = List?.rows ? List?.rows?.length : List?.length
        const startFrom = ((activePageNo - 1) * offset) + 1
        const end = (startFrom - 1) + userArrLength
        setStartingNo(startFrom)
        setEndingNo(end)
        setList(List?.rows ? List?.rows : List)
      } else if (transactionsTotalCount?.count === List?.rows?.length && isFullList) {
        setFullList(List?.rows ? List?.rows : [])
        setLoading(false)
        exporter.current.props = {
          ...exporter?.current?.props,
          data: processExcelExportData(List?.rows ? List?.rows : []),
          fileName: 'Transactions.xlsx'
        }
        exporter.current.save()
        setLoader(false)
      }
      setLoading(false)
    }
    if (previousProps?.transactionsTotalCount !== transactionsTotalCount && transactionsTotalCount) {
      setTotal(transactionsTotalCount?.count ? transactionsTotalCount?.count : 0)
    }
    return () => {
      previousProps.List = List
      previousProps.transactionsTotalCount = transactionsTotalCount
    }
  }, [List, transactionsTotalCount])

  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        if (resStatus) {
          const startFrom = 0
          const limit = offset
          if (isLeaguePassbook) {
            leaguePassbookList(startFrom, limit, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
            leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
          } else {
            getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
            getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
          }
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
          setPageNo(1)
        } else {
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
          setLoading(false)
        }
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // will be called when page changes occurred
  useEffect(() => {
    if ((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current && start) {
      if (isLeaguePassbook) {
        leaguePassbookList(start, offset, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(start, offset, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setLoading(true)
    } else if (previousProps?.offset !== offset) {
      if (isLeaguePassbook) {
        leaguePassbookList(start, offset, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(start, offset, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(searchProp, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
      setLoading(true)
    } else if ((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current) {
      if (isLeaguePassbook) {
        leaguePassbookList(0, offset, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(0, offset, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      }
    }
    return () => {
      previousProps.start = start
      previousProps.offset = offset
    }
  }, [start, offset])

  // function to sorting to the nameOrder and createdOrder
  function onSorting (sortingBy) {
    const isActivityDate = sortingBy === 'dActivityDate'
    const order = isActivityDate ? nameOrder : createdOrder
    const newOrder = order === 'asc' ? 'desc' : 'asc'
    const startFrom = 0
    const limit = offset
    const listFunction = isLeaguePassbook ? leaguePassbookList : getList
    const extraParams = isLeaguePassbook ? [] : [userType, SportsType, match]
    listFunction(startFrom, limit, sortingBy, newOrder, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, ...extraParams, isFullResponse)
    setOrder(newOrder)
    setPageNo(1)
    setLoading(true)
    setSort(sortingBy)
    if (isActivityDate) {
      setNameOrder(newOrder)
    } else {
      setCreatedOrder(newOrder)
    }
  }

  // will be called when sports type changes occured
  useEffect(() => {
    if (previousProps.SportsType !== SportsType) {
      const startFrom = 0
      const limit = offset
      setListOfMatches([])
      getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      matchListing(0, 10, '', SportsType)
      getMatchesTotalCountFunc(searchValue, SportsType)
      setPageNo(activePageNo)
      setLoading(true)
    }
    return () => {
      previousProps.SportsType = SportsType
    }
  }, [SportsType])

  // will be called when match  changes occured
  useEffect(() => {
    if (previousProps?.SportsType !== SportsType && previousProps?.matchList !== matchList) {
      const startFrom = 0
      const limit = offset
      if (isLeaguePassbook) {
        leaguePassbookList(startFrom, limit, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
        leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      } else {
        getList(startFrom, limit, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
        matchListing(0, 10, '', SportsType)
        getMatchesTotalCountFunc(searchValue, SportsType)
      }
      setPageNo(activePageNo)
      setLoading(true)
    }
    return () => {
      previousProps.SportsType = SportsType
      previousProps.matchList = matchList
    }
  }, [SportsType, matchList])

  const processExcelExportData = data => data?.map((passbookList, index) => {
    const srNo = index + 1
    const sUsername = passbookList?.sUsername ? passbookList?.sUsername : '--'
    const sName = passbookList?.sName ? passbookList?.sName : '--'
    const sEmail = passbookList?.sEmail ? passbookList?.sEmail : '--'
    const sMobNum = passbookList?.sMobNum ? passbookList?.sMobNum : '--'
    const sPromocode = passbookList?.sPromocode || '--'
    const matchName = passbookList?.sMatchName || '--'
    const matchDateTime = passbookList?.dMatchStartDate ? moment(passbookList?.dMatchStartDate)?.format('MM/DD/YYYY hh:mm A') : '--'
    const sRemarks = passbookList?.sRemarks ? passbookList?.sRemarks : '--'
    let dActivityDate = moment(passbookList?.dActivityDate)?.local()?.format('MM/DD/YYYY hh:mm A')
    const type = passbookList?.eType === 'Cr' ? 'Credited' : passbookList?.eType === 'Dr' ? 'Debited' : '--'
    dActivityDate = dActivityDate === 'Invalid date' ? ' - ' : dActivityDate
    const iTransactionId = passbookList?.iTransactionId || '--'
    const eTransactionType = passbookList?.eTransactionType || '--'
    const nLoyaltyPoint = passbookList?.nLoyaltyPoint || '--'
    const eCategory = passbookList?.eCategory
    return {
      ...passbookList,
      srNo,
      sUsername,
      sName,
      sEmail,
      sMobNum,
      dActivityDate,
      sPromocode,
      iTransactionId,
      eTransactionType,
      matchName,
      matchDateTime,
      type,
      sRemarks,
      nLoyaltyPoint,
      eCategory
    }
  })

  async function onExport () {
    if (startDate && endDate) {
      if (isLeaguePassbook) {
        await leaguePassbookList(start, offset, sort, 'asc', search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, true)
        await leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, true)
      } else {
        await getList(start, offset, sort, 'asc', userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, true)
        await getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, true)
      }
      setLoader(true)
    } else {
      setMessage('Please Select Date Range')
      setModalMessage(true)
      setStatus(false)
    }
  }

  useImperativeHandle(ref, () => ({
    onExport,
    onRefresh
  }))

  // Define a function to determine the cell color based on the content
  const getCellColor = (content) => {
    if (content === 'Credited') {
      return '#39C679'
    } else if (content === 'Debited') {
      return '#FF3D3D'
    }
    return ''
  }

  function onRefresh () {
    // const startFrom = 0
    if (isLeaguePassbook) {
      leaguePassbookList(start, offset, sort, order, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
      leagueTransactionsTotalCountFunc(search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, isFullResponse)
    } else {
      getList(start, offset, sort, order, userType, search, searchTypeBy, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
      getTransactionsTotalCountFunc(search, searchTypeBy, userType, startDate, endDate, particulars, eType, transactionStatus, SportsType, match, isFullResponse)
    }
    setPageNo(activePageNo)
    setLoading(true)
  }

  function mapData (data, i) {
    const newData = columnNames?.filter((c) => c.bShow)
    const tableCells = []
    for (let k = 0; k < newData.length; k++) {
      const column = newData[k]?.sColumnName
      switch (column) {
        case 'Passbook ID':
          tableCells.push(<td key={k}>{data?.id ? data?.id : '--'}</td>)
          break
        case 'Status':
          tableCells.push(
            <td key={k}>
              {data?.eStatus === 'CMP' ? (<Badge className='match-status-cmp ml-2' color='#E6FAEF'>Completed</Badge>) : ''}
              {data?.eStatus === 'R' ? (<Badge className='match-status-r ml-2' color='#F1F4FF'>Refunded</Badge>) : ''}
              {data?.eStatus === 'CNCL' ? (<Badge className='match-status-cancl ml-2' color='#F1F4FF'>Cancelled</Badge>) : ''}
            </td>
          )
          break
        case 'User Type':
          tableCells.push(<td key={k}>{data.eUserType ? data.eUserType === 'U' ? 'User' : 'Admin' : '--'}</td>)
          break
        case 'Username':
          tableCells.push(
            <td key={k}>
              {(adminPermission && adminPermission?.USERS !== 'N' && adminPermission?.SYSTEM_USERS === 'N' && data?.eUserType === 'U')
                ? (<Button className='total-text-link' color="link" tag={Link} to={`/users/user-management/user-details/${data?.iUserId}`}>{data?.sUsername || '--'}</Button>)
                : (adminPermission && adminPermission?.USERS === 'N' && adminPermission?.SYSTEM_USERS !== 'N' && data?.eUserType !== 'U')
                    ? (<Button className='total-text-link' color="link" tag={Link} to={`/users/system-user/system-user-details/${data?.iUserId}`}>{data?.sUsername || '--'}</Button>)
                    : (adminPermission && (adminPermission?.USERS !== 'N' && adminPermission?.SYSTEM_USERS !== 'N'))
                        ? (
                      <Button
                        style={{ background: 'none', border: 'none' ,padding:'0'}}
                        className='total-text-link'
                        color={data?.eUserType === 'U' ? 'link' : undefined}
                        tag={data?.eUserType === 'U' ? Link : undefined} // Set to undefined if condition is false
                        to={data?.eUserType === 'U' ? `/users/user-management/user-details/${data?.iUserId}` : undefined} // Set to undefined if condition is false
                      >
                        {data?.sUsername || '--'}
                      </Button>
                        // <Button className='total-text-link' color={data?.eUserType === 'U' ? 'link' : ''} tag={data?.eUserType === 'U' ? Link :''} to={data?.eUserType === 'U' ? `/users/user-management/user-details/${data?.iUserId}` : ``}>{data?.sUsername || '--'}</Button>
                      )
                        : <span>{data?.sUsername || '--'}</span>
              }
            </td>)
          break
        case 'Mobile No.':
          tableCells.push(<td key={k}>{data?.sMobNum || '--'}</td>)
          break
        case 'Amount':
          tableCells.push(
            <td key={k}>
              {data?.nAmount ? data?.nAmount : 0}
              <br />
              <b>{data?.nCash !== 0 || data?.nBonus !== 0 ? `(${data?.nCash} + ${data?.nBonus})` : ''}</b>
            </td>
          )
          break
        case 'Loyalty Point':
          tableCells.push(<td key={k}>{data?.nLoyaltyPoint || '--'}</td>)
          break
        case 'Available Total Balance':
          tableCells.push(
            <td key={k}>
              {data?.nNewTotalBalance ? data?.nNewTotalBalance : 0}
              <br />
              <b>{data?.nNewDepositBalance !== 0 || data?.nNewWinningBalance !== 0 ? `(${data?.nNewDepositBalance} + ${data?.nNewWinningBalance})` : ''}</b>
            </td>
          )
          break
        case 'Promocode':
          tableCells.push(<td key={k}>{data?.sPromocode || '--'}</td>)
          break
        case 'Available Bonus':
          tableCells.push(<td key={k}>{data?.nNewBonus ? data?.nNewBonus : 0}</td>)
          break
        case 'Sport':
          tableCells.push(<td key={k}>{data?.eCategory || '--'}</td>)
          break
        case 'Type':
          tableCells.push(
            <td style={{ color: getCellColor(data?.eType && data?.eType === 'Cr' ? 'Credited' : data?.eType === 'Dr' ? 'Debited' : '--') }}>
              {data?.eType && data?.eType === 'Cr' ? 'Credited' : data?.eType === 'Dr' ? 'Debited' : '--'}
            </td>)
          break
        case 'Transaction Type':
          tableCells.push(<td key={k}>{data?.eTransactionType ? data?.eTransactionType : '--'}</td>)
          break
        case 'Transaction ID':
          tableCells.push(<td key={k}>{data?.iTransactionId || '--'}</td>)
          break
        case 'Request Date & Time':
          tableCells.push(<td key={k}>{data?.dActivityDate ? moment(data?.dActivityDate)?.format('lll') : ' - '}</td>)
          break
        case 'Approval Date & Time':
          tableCells.push(<td key={k}>{data?.dProcessedDate ? moment(data?.dProcessedDate)?.format('lll') : ' - '}</td>)
          break
        case 'Match':
          tableCells.push(
            <td key={k}>
              {data?.sMatchName || '--'}
              <br />
              {data?.dMatchStartDate ? moment(data?.dMatchStartDate)?.format('lll') : ''}
            </td>
          )
          break
        case 'Remarks':
          tableCells.push(<td key={k}>{data?.sRemarks ? data?.sRemarks : '-'}</td>)
          break
        default:
          tableCells.push(<td key={k}>--</td>)
          break
      }
    }
    return <tr key={i}>{tableCells}</tr>
  }

  return (
    <Fragment>
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <ExcelExport ref={exporter} data={fullList && fullList?.length > 0 ? fullList : list} fileName={props?.isUserToPassbook ? `Transactions - ${list[0]?.sUsername}.xlsx` : 'Transactions.xlsx'}>
        <ExcelExportColumn field="srNo" title="Sr No" />
        <ExcelExportColumn field="id" title="ID" />
        <ExcelExportColumn field="sUsername" title="Username" />
        <ExcelExportColumn field="sEmail" title="Email" />
        <ExcelExportColumn field="sMobNum" title="Mobile No" />
        <ExcelExportColumn field="nAmount" title="Amount" />
        {/* <ExcelExportColumn field="nLoyaltyPoint" title="Loyalty Point" /> */}
        <ExcelExportColumn field="nNewTotalBalance" title="Available Total Balance" />
        <ExcelExportColumn field="nNewBonus" title="Available Bonus" />
        {/* <ExcelExportColumn field="eCategory" title="Sport" /> */}
        {/* <ExcelExportColumn field="sPromocode" title="Promocode" /> */}
        <ExcelExportColumn field="type" title="Type" />
        <ExcelExportColumn field="eTransactionType" title="Transaction Type" />
        <ExcelExportColumn field="iTransactionId" title="Transaction ID" />
        {/* <ExcelExportColumn field="matchName" title="Match" /> */}
        {/* <ExcelExportColumn field="matchDateTime" title="Match Date & Time" /> */}
        <ExcelExportColumn field="dActivityDate" title="Activity Date" />
        <ExcelExportColumn field="sRemarks" title="Remarks" />
      </ExcelExport>

      {loader && <Loading />}
      {!loading && list?.length === 0
        ? (<DataNotFound message="Transactions" obj={obj} />)
        : (!loading && (
          <div className='table-represent'>
            <div className="table-responsive">
              {/* New Dynamic Table */}
              <table className='table'>
                <thead>
                  <tr>
                    {columnNames?.map((c, i) => {
                      if (c?.sColumnName === 'Amount' && c?.bShow) {
                        return (
                          <th key={i}>
                            Amount
                            <br />
                            (Cash + Bonus)
                          </th>
                        )
                      } else if (c?.sColumnName === 'Available Total Balance' && c?.bShow) {
                        return (
                          <th key={i}>
                            Available Total Balance
                            <br />
                            (Available Deposit Balance
                            {' '}
                            <br />
                            + Available Winning Balance)
                          </th>
                        )
                      } else if (c?.sColumnName === 'Request Date & Time' && c?.bShow) {
                        return (
                          <th key={i}>
                            Request Date & Time
                            <Button className="sort-btn" color="link" onClick={() => onSorting('dActivityDate')}><img alt="sorting" className="m-0 d-block" src={sortIcon} /></Button>
                          </th>
                        )
                      } else if (c?.sColumnName === ' Approval Date & Time' && c?.bShow) {
                        return (
                          <th key={i}>
                            Approval Date & Time
                            <Button className="sort-btn" color="link" onClick={() => onSorting('dProcessedDate')}><img alt="sorting" className="m-0 d-block" src={sortIcon} /></Button>
                          </th>
                        )
                      } else if (c?.bShow) {
                        return <th key={i}>{c?.sColumnName}</th>
                      } else return null
                    })}
                  </tr>
                </thead>
                <tbody>
                  {list && list?.length !== 0 && list?.map((data, i) => { return (mapData(data, i)) })}
                </tbody>
              </table>
            </div>
          </div>
          )
          )
      }

      {list?.length !== 0 && (
        <PaginationComponent
          activePageNo={activePageNo}
          endingNo={endingNo}
          listLength={listLength}
          offset={offset}
          paginationFlag={paginationFlag}
          setListLength={setListLength}
          setLoading={setLoading}
          setOffset={setOffset}
          setPageNo={setPageNo}
          setStart={setStart}
          startingNo={startingNo}
          total={total}
        />
      )}
    </Fragment>
  )
})

PassbookList.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  flag: PropTypes.bool,
  getList: PropTypes.func,
  List: PropTypes.object,
  location: PropTypes.object,
  search: PropTypes.string,
  getTransactionsTotalCountFunc: PropTypes.func,
  searchType: PropTypes.string,
  isLeaguePassbook: PropTypes.string,
  leaguePassbookList: PropTypes.array,
  leagueTransactionsTotalCountFunc: PropTypes.func,
  userToPassbookId: PropTypes.string,
  tdsToPassbookId: PropTypes.number,
  isUserToPassbook: PropTypes.bool,
  isTdsToPassbook: PropTypes.bool,
  userType: PropTypes.string,
  columnNames: PropTypes.array,
  getGameCategory: PropTypes.func,
  SportsType: PropTypes.string,
  match: PropTypes.string,
  listOfMatches: PropTypes.array,
  setSelectedMatch: PropTypes.func,
  matchList: PropTypes.object,
  setListOfMatches: PropTypes.func,
  matchInput: PropTypes.string,
  matchListing: PropTypes.func,
  getMatch: PropTypes.func,
  getMatchesTotalCountFunc: PropTypes.func,
  searchValue: PropTypes.string,
  setMatchTotal: PropTypes.func,
  eType: PropTypes.string,
  particulars: PropTypes.string
}

PassbookList.displayName = PassbookList

export default PassbookList
