import axios from '../../axios'
const getEventsList = async (start, limit,status) => {
  let url = `/ot/admin/event/list/v1?start=${start}&limit=${limit}`
  if(status){
    url += `&eStatus=${status}`
  }
  return await axios.get(url)
}

export default getEventsList
