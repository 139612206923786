import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Navbar from './Navbar';
import { useQuery } from '@tanstack/react-query';
import { io } from 'socket.io-client';
import SocketContextProvider from '../context/SocketContextProvider';
// import { useDispatch } from 'react-redux';
// import { FETCH_SOCKET } from '../actions/constants';
function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('Token');
  const {id} = useParams();
  const [searchParams] = useSearchParams()
  const eventId = searchParams.get('eventId')
  const threadId = searchParams.get('threadId')
  // const dispatch = useDispatch()
  const socketQueryFn = () => {
    if (!token) {
      return Promise.reject(new Error("No token available"));
    }
    return io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      transports: ['websocket', 'polling', 'flashsocket'],
      auth: {
        Authorization: token,
      },
    });
  };
  const { data: socket } = useQuery({queryKey: ['socketConnection', token], queryFn: socketQueryFn});
  useEffect(() => {
    if (token && socket) {
      if (socket.connected) {
        console.log('Socket is connected.');
      } else {
        console.log('Socket is not connected.');
        socket.connect();
      }

      socket.on("connect", () => {
        console.log('Socket connected successfully.');
      });

      socket.on("connect_error", (error) => {
        console.log("Connection Error:", error);
      });
      socket.on('update', (data) => {
        console.log('Received update event:', data);
      });
      socket.on("disconnect", (reason, details) => {
        console.log("%cDisconnected:", 'color: orange', reason, details);
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          socket.connect();
        }
      });
      if(id || eventId){
        socket.emit('message',  {
          eType: 'SUBSCRIBE_ADMIN_EVENT_UPDATES',
          oData: { iEventId: id ||eventId },
      }, (response) => {
          console.log('---------------- Live Update ----------------',response);
      });
      }
      if(threadId){
        socket.emit('message',  {
          eType: 'SUBSCRIBE_ADMIN_MSG_UPDATES',
          oData: { iThreadId: threadId },
      }, (response) => {
          console.log('---------------- Live Chat ----------------',response);
      });
      }
  }

    return () => {
      // if (socket) {
      //   socket.disconnect();
      // }
    };
  }, [location.pathname, navigate, socket, token]);
  return (
    <div className='main d-flex vh-100 overflow-hidden'>
      <SocketContextProvider socket={socket}>
        <div className='navbar-component flex-shrink-0'>
          <Navbar />
        </div>
        <div className='flex-grow-1'>
          <Outlet />
        </div>
      </SocketContextProvider>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
