import React, { Fragment } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types' 

import backIcon from '../../../assets/images/back-icon-1.svg'
import exportIcon from '../../../assets/images/export-icon.svg'
import maintenance from '../../../assets/images/Maintenance.svg'
import infoIcon from '../../../assets/images/info-icon.svg'
import { useSelector } from 'react-redux'
function MainHeading (props) {
  const {
    Auth, adminPermission, onSubmit, submitDisableButton, note,
    promocodeStatistics, automatedNotification, sliderStatistics, heading, list, maintenancePermission, getMaintenanceModeFunc, leadershipBoard, calculateLeaderBoardData,
    AddVersion, cancelLink,AddEvent, AddSport, AddSlider,AddUserProfile, AddSetting, AddPromocode, AddPopUpAd, UpdatePayout, AddPayment, AddOffer, AddContent, AddRuleComponent, AddNPromocode,
    UpdateNotification, UpdateComplaint, UpdateEmailTemplate, updateCurrencyData,
    button, onEdit, onAdd,AddStreaks,
    Title, Description, Details, Slug, UserStreaks, priority,
    imageSubmit, conditionUrl, Key, AllReports, Registration, submit, lastUpdatedDate,
    addItem, sendInAppNotification,EditCategory,EditSubCategory,addOrder,eventReport
  } = props
  // eslint-disable-next-line react/prop-types
  const page = JSON.parse(localStorage.getItem('queryParams'))
  const navigate = useNavigate()
  const location = useLocation()
  const adminDetails = useSelector(state => state?.auth?.adminData)
  return (
    <div className='header-block-setting'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex inline-input align-items-center'>
          {promocodeStatistics ? <img className='custom-go-back mr-2' alt="promo" height='22' onClick={() => navigate(`/settings/promocode-management${page?.PromoCodeManagement || ''}`)} src={backIcon} width='22' /> : ''}
          {automatedNotification ? <img className='custom-go-back mr-2' alt="push" height='22' onClick={() => navigate(`/content-management/push-notification${page?.PushNotificationManagement || ''}`)} src={backIcon} width='22' /> : ''}
          {sliderStatistics ? <img className='custom-go-back mr-2' height='22' alt="slider" onClick={() => navigate(`/content-management/slider-management${page?.SliderManagement || ''}`)} src={backIcon} width='22' /> : ''}
          {(AddSport || AddVersion || AddSlider || AllReports || EditSubCategory || EditCategory || AddStreaks ||AddUserProfile||AddEvent|| AddSetting || AddPromocode || AddPopUpAd || UpdatePayout || AddPayment || AddOffer || UpdateNotification || UpdateComplaint || UpdateEmailTemplate || AddContent || AddRuleComponent || AddNPromocode || Registration || addItem || sendInAppNotification || addOrder )
            ? <img className='custom-go-back mr-2' alt="" height='22' onClick={() =>{navigate(cancelLink || -1)}} src={backIcon} width='22' />
            : ''}
          {/* {eventReport ?
           <img
           alt='go-back'
             className='custom-go-back ms-3'
             height='24'
             onClick={() => (location?.state && location?.state?.eventList)
              ? navigate(-3)
                : (page?.EventManagement)
                    ? navigate(`/events/event-management${page?.EventManagement || ''}`)
                    : navigate(-1)}
             src={backIcon}
             width='24'
           />
            : ''} */}
          <h2 className='setting-heading'>
            {heading}
            {props?.info && (
            <Fragment>
              <img className='custom-info' alt="info" id='info' src={infoIcon} />
              <UncontrolledTooltip className="bg-default-s" delay={0} placement="right" target="info">
                <p>
                  After updating anything from here, It will take some time to reflect on the app.
                </p>
                <p>{note}</p>
              </UncontrolledTooltip>
            </Fragment>
            )}
          </h2> 
        </div>
        <div className='btn-list-user'>
          {AddRuleComponent && <Button className="theme-btn icon-btn-cancel " tag={Link} to="/settings/common-rules">Cancel</Button>}
          {UpdateComplaint && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/settings/feedback-complaint-management${page?.FeedbackManagement || ''}`}>Cancel</Button>}
          {AddPayment && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/settings/payment-management${page?.PaymentManagement || ''}`}>Cancel</Button>}
          {UpdatePayout && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/settings/payout-management${page?.PayoutManagement || ''}`}>Cancel</Button>}
          {AddPromocode && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/settings/promocode-management${page?.PromoCodeManagement || ''}`}>Cancel</Button>}
          {AddNPromocode && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/settings/promocode-management${page?.PromoCodeManagement || ''}`}>Cancel</Button>}
          {AddSetting && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/settings/setting-management${page?.SettingManagement || ''}`}>Cancel</Button>}
          {AddVersion && <Button className="theme-btn icon-btn-cancel" tag={Link} to={`/settings/versions${page?.VersionManagement || ''}`}>Cancel</Button>}
          {/*  Content - Management  */}
          {AddContent && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/content-management/content${page?.ContentManagement || ''}`}>Cancel</Button>}
          {UpdateEmailTemplate && <Button className="theme-btn icon-btn-cancel " tag={Link} to="/content-management/email-template">Cancel</Button>}
          {AddEvent && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/events/event-management/`}>Cancel</Button>}
          {addOrder && <Button className="theme-btn icon-btn-cancel " tag={Link} to={cancelLink}>Cancel</Button>}
          {AddSlider && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/content-management/slider-management${page?.SliderManagement || ''}`}>Cancel</Button>}
          {AddUserProfile && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/user-profile-level${page?.UserXPManagement || ''}`}>Cancel</Button>}
          {AddPopUpAd && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/content-management/popup-ads-management${page?.PopupAdsManagement || ''}`}>Cancel</Button>}
          {AddSport && <Button className="theme-btn icon-btn-cancel " tag={Link} to="/content-management/sports">Cancel</Button>}
          {UpdateNotification && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/content-management/notification-management${page?.NotificationManagement || ''}`}>Cancel</Button>}
          {AddOffer && <Button className="theme-btn icon-btn-cancel " tag={Link} to={`/content-management/offer-management${page?.OfferManagement || ''}`}>Cancel</Button>}
          {(heading === 'Registration' && <Button className="theme-btn icon-btn-cancel " tag={Link} to="/affiliate/affiliate-list">Cancel</Button>)}
          {addItem && <Button className="theme-btn icon-btn-cancel " tag={Link} to='/content-management/merchandise'>Cancel</Button>}
          {sendInAppNotification && <Button className="theme-btn icon-btn-cancel " tag={Link} to='/content-management/notification-management'>Cancel</Button>}
            {/* Category management  */}
          {EditCategory && <Button className="theme-btn icon-btn-cancel" tag={Link} to={`/category-management/category`}>Cancel</Button>}
          {EditSubCategory && <Button className="theme-btn icon-btn-cancel" tag={Link} to={`/category-management/subcategory`}>Cancel</Button>}

          {props?.onExport && list && ((list?.total > 0 || list?.length >= 1 || list?.nTotal >= 1) || (list?.total > 0 || list?.length >= 1 || list?.count >= 1)) && (
            <Button className="theme-btn icon-btn-export " onClick={props?.onExport}>
              <img alt='excel' src={exportIcon}  />
              {props?.export}
            </Button>
          )}
          {maintenancePermission && (
            <Button className='icon-btn-maintenance' onClick={getMaintenanceModeFunc}>
              <img alt="maintenance" src={maintenance} title='Maintenance' />
              {props?.maintenance}
            </Button>
          )}

          {props?.refresh && (
            <Button className="theme-btn icon-btn-refresh refresh" onClick={props?.onRefresh}>
              {props?.refresh}
            </Button>
          )}
          {leadershipBoard && (
            <div className='d-flex align-items-center'>
              {lastUpdatedDate && (
              <div className='last-updated-date mr-3'>
                <div className='dt'>{lastUpdatedDate}</div>
                <div className='msg'>Last Update Date</div>
              </div>
              )}

              <Button className="theme-btn icon-btn-refresh  refresh" onClick={calculateLeaderBoardData} >
                Refresh
              </Button>
            </div>

          )}

          {AddRuleComponent && ((Auth && Auth === 'SUPER') || (adminPermission?.RULE !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>Save Changes</Button>
          </Fragment>
          )}
          {props?.onExport && AllReports
            ? (
              <Button className="theme-btn icon-btn-export " onClick={props?.onExport}>
                <img alt='excel' src={exportIcon} />
                {props?.export}
              </Button>
              )
            : ''}
          {props?.onExport && UserStreaks
            ? (
              <Button className="theme-btn icon-btn-export " onClick={props?.onExport}>
                <img alt='excel' src={exportIcon} />
                {props?.export}
              </Button>
              )
            : ''}
          {AddContent && ((Auth && Auth === 'SUPER') || (adminPermission?.CMS !== 'R')) && (
          <Fragment>
            <Button className="theme-btn" disabled={(!Slug || !Title || !Details || !priority ) || submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          { UpdateComplaint && ((Auth && Auth === 'SUPER') || (adminPermission?.COMPLAINT !== 'R')) && (
          <Fragment>
            <Button className="theme-btn" disabled={submitDisableButton} onClick={onEdit}>Save Changes</Button>
          </Fragment>
          )}
          {UpdateNotification && ((Auth && Auth === 'SUPER') || (adminPermission?.NOTFICATION !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit} >Save Changes</Button>
          </Fragment>
          )}
          {sendInAppNotification && ((Auth && Auth === 'SUPER') || (adminPermission?.NOTIFICATION !== 'R')) && (
            <Fragment>
              <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit} >Send Notification</Button>
            </Fragment>
          )}
          {AddOffer && ((Auth && Auth === 'SUPER') || (adminPermission?.OFFER !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={(!Title || !Details || !Description) || submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          {addItem && ((Auth && Auth === 'SUPER') || (adminPermission?.MERCHANDISE !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          {AddPayment && ((Auth && Auth === 'SUPER') || (adminPermission?.PAYMENT_OPTION !== 'R')) && (
          <Button className="theme-btn" disabled={submitDisableButton} onClick={onSubmit}>Save Changes</Button>
          )}
          {UpdatePayout && ((Auth && Auth === 'SUPER') || (adminPermission?.PAYOUT_OPTION !== 'R')) && (
          <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>Save Changes</Button>
          )}
          {AddPopUpAd && ((Auth && Auth === 'SUPER') || (adminPermission?.POPUP_ADS !== 'R')) && (
          <Fragment>
            <Button className="theme-btn" disabled={submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          {AddPromocode && ((Auth && Auth === 'SUPER') || (adminPermission?.PROMO !== 'R')) && (
          <Fragment>
            <Button className="theme-btn" disabled={submitDisableButton} onClick={onAdd}>{button}</Button>
          </Fragment>
          )}
          {AddSetting && ((Auth && Auth === 'SUPER') || (adminPermission?.SETTING !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={(e) => (Key === 'BG' || Key === 'IMG') ? imageSubmit(Key) : Key === 'CURRENCY' ? updateCurrencyData() : onSubmit(e)}>Save Changes</Button>
          </Fragment>
          )}
          {AddSlider && ((Auth && Auth === 'SUPER') || (adminPermission?.BANNER !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          {AddUserProfile && ((Auth && Auth === 'SUPER') || (adminPermission?.PROFILE_LEVEL !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          {AddEvent && ((Auth && Auth === 'SUPER') || (adminPermission?.OT_EVENT !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          {addOrder && ((Auth && Auth === 'SUPER') || (adminPermission?.OT_EVENT !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>{button}</Button>
          </Fragment>
          )}
          {AddSport && ((Auth && Auth === 'SUPER') || (adminPermission?.SPORT !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>Save Changes</Button>
          </Fragment>
          )}
          {AddVersion && ((Auth && Auth === 'SUPER') || (adminPermission?.VERSION !== 'R')) && (
          <Fragment>
            <Button className='theme-btn' disabled={submitDisableButton} onClick={onSubmit}>{conditionUrl ? ' Save Changes' : 'Add Version'}</Button>
          </Fragment>
          )}
          {AddVersion && ((Auth && Auth === 'SUPER') || (adminPermission?.VERSION !== 'R')) && (
          <Fragment>
            <Button className='theme-btn' disabled={submitDisableButton} onClick={onSubmit}>{' Save Changes'}</Button>
          </Fragment>
          )}
          {UpdateEmailTemplate && ((Auth && Auth === 'SUPER') || (adminPermission?.EMAIL_TEMPLATES !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit}>Save Changes</Button>
          </Fragment>
          )}
          {AddNPromocode && ((Auth && Auth === 'SUPER') || (adminPermission?.PROMO !== 'R')) && (
          <Fragment>
            <Button className="theme-btn" disabled={submitDisableButton} onClick={onAdd}>{button}</Button>
          </Fragment>
          )}
           {EditCategory && ((Auth && Auth === 'SUPER') || (adminPermission?.OT_CATEGORY !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit} >Save Changes</Button>
          </Fragment>
          )}
           {EditSubCategory && ((Auth && Auth === 'SUPER') || (adminPermission?.OT_CATEGORY !== 'R')) && (
          <Fragment>
            <Button className="theme-btn " disabled={submitDisableButton} onClick={onSubmit} >Save Changes</Button>
          </Fragment>
          )}
          {heading === 'Registration' && (
            <Fragment>
              <Button className="theme-btn" disabled={submitDisableButton} onClick={submit}>{button}</Button>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}
MainHeading.propTypes = {
  promocodeStatistics: PropTypes.any,
  automatedNotification: PropTypes.bool,
  sliderStatistics: PropTypes.bool,
  heading: PropTypes.string,
  info: PropTypes.bool,
  onExport: PropTypes.func,
  // list: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.string,
  onRefresh: PropTypes.func,
  maintenancePermission: PropTypes.bool,
  getMaintenanceModeFunc: PropTypes.func,
  export: PropTypes.string,
  leadershipBoard: PropTypes.bool,
  calculateLeaderBoardData: PropTypes.func,
  maintenance: PropTypes.string,
  /**
   * on not passing cancelLink
   * page navigates back to previous route
   * navigate(-1)
   */
  cancelLink: PropTypes.string,
  AddVersion: PropTypes.bool,
  EditCategory: PropTypes.bool,
  AddSport: PropTypes.bool,
  AddSlider: PropTypes.bool,
  AddSetting: PropTypes.bool,
  AddPromocode: PropTypes.bool,
  AddPopUpAd: PropTypes.bool,
  UpdatePayout: PropTypes.bool,
  AddPayment: PropTypes.bool,
  AddOffer: PropTypes.bool,
  UpdateNotification: PropTypes.bool,
  UpdateComplaint: PropTypes.bool,
  UpdateEmailTemplate: PropTypes.bool,
  AddContent: PropTypes.bool,
  AddRuleComponent: PropTypes.bool,
  Auth: PropTypes.string,
  // adminPermission: PropTypes.string,
  onSubmit: PropTypes.func,
  button: PropTypes.string,
  onEdit: PropTypes.func,
  Title: PropTypes.string,
  Description: PropTypes.string,
  Details: PropTypes.string,
  onAdd: PropTypes.func,
  updateCurrencyData: PropTypes.func,
  imageSubmit: PropTypes.func,
  Key: PropTypes.string,
  conditionUrl: PropTypes.string,
  AllReports: PropTypes.bool,
  AddNPromocode: PropTypes.bool,
  note: PropTypes.string,
  Registration: PropTypes.bool,
  submit: PropTypes.func,
  Slug: PropTypes.string,
  Category: PropTypes.string,
  priority: PropTypes.number,
  lastUpdatedDate: PropTypes.string,
  addItem: PropTypes.bool,
  sendInAppNotification: PropTypes.bool
}

export default MainHeading
