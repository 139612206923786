import { useDispatch, useSelector } from "react-redux"
import AlertMessage from "../../../../components/AlertMessage"
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Loading from "../../../../components/Loading"
import { getAdminBuyCommission, getEventDetails } from "../../../../actions/events"
import { getUrl } from "../../../../actions/url"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import RequiredField from "../../../../components/RequiredField"
import { currencySymbol, modalMessageFunc } from "../../../../helpers/helper"
import { addOrder } from "../../../../actions/orders"
import OrderBook from "../OrderBook"
import getOrderBook from "../../../../api/OrderManagement/getOrderBook"
import SocketContext from "../../../../context/SocketContext"
import { useQuery } from "@tanstack/react-query"

const AddOrderForm = forwardRef((props, ref) => {
    const { isCreate, setIsEdit, setIsCreate, adminPermission, setSubmitDisableButton } = props
    const { id } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const eventId = searchParams.get('eventId')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState(false)
    const [close, setClose] = useState(false)
    const [modalMessage, setModalMessage] = useState(false)

    const [priceMarket, setPriceMarket] = useState(1)
    const [priceMarketErr, setPriceMarketErr] = useState('')
    const [isOpenMarketInput, setIsOpenMarketInput] = useState(false)
    const [selectOption, setSelectOption] = useState('')
    const [selectOptionErr, setSelectOptionErr] = useState('')
    const [priceRange, setPriceRange] = useState(1)
    const [priceRangeErr, setPriceRangeErr] = useState('')
    const [quantity, setQuantity] = useState(1)
    const [quantityErr, setQuantityErr] = useState('')
    const [stopLoss, setStopLoss] = useState(false)
    const [stopLossPrice, setStopLossPrice] = useState(0.5)
    const [bookProfit, setBookProfit] = useState(false)
    const [bookProfitPrice, setBookProfitPrice] = useState(0.5)
    const [marketPriceErr, setMarketPriceErr] = useState('')
    const [marketPrice, setMarketPrice] = useState(1)

    const dispatch = useDispatch()
    const eventDetails = useSelector(state => state?.events?.eventDetails)
    const resStatus = useSelector(state => state?.orders?.resStatus)
    const resMessage = useSelector(state => state?.orders?.resMessage)
    const adminCommission = useSelector(state => state?.orders?.adminCommission)
    const previousProps = useRef({ resStatus, resMessage, eventDetails, adminCommission })?.current


    const socket = useContext(SocketContext)
    const [activeTab, setActiveTab] = useState('BUY');
    const [newOrderBookList,setNewOrderBookList] = useState()
    const { data: orderBookList } = useQuery({
        queryKey: ['getOrderBook', id || eventId, activeTab],
        queryFn: () => getOrderBook(id ||  eventId, activeTab),
        select: (res) => res?.data?.data
    })
    useEffect(()=>{
        if(orderBookList?.length){
            setNewOrderBookList(orderBookList)
        }
    },[orderBookList])
    useEffect(() => {
        if (socket) {
          socket.on('ADMIN_UPDATES', (data) => {
            if(activeTab==='BUY' && data?.eType ==='ORDER_BOOK_UPDATE'){
                setNewOrderBookList(data?.oData?.oOrderBook?.BUY?.length ? data?.oData?.oOrderBook?.BUY : orderBookList)
                // console.log(data, 'Received ADMIN_UPDATES event BUY',data?.oData?.oOrderBook?.BUY,data?.oData?.oOrderBook?.BUY?.length);
            }else if(activeTab==='SELL' && data?.eType ==='ORDER_BOOK_UPDATE'){
                setNewOrderBookList(data?.oData?.oOrderBook?.SELL?.length ? data?.oData?.oOrderBook?.SELL : orderBookList)
                // console.log(data, 'Received ADMIN_UPDATES event SELL',data?.oData?.oOrderBook?.SELL,data?.oData?.oOrderBook?.SELL?.length);
            }
          });
        }
      }, [socket]); 

    useEffect(() => {
        if (eventId) {
            dispatch(getEventDetails({ Id: eventId }))
            setIsCreate(false)
        } else {
            setIsEdit(true)
        }
        dispatch(getAdminBuyCommission())
        dispatch(getUrl('media'))
    }, [])
    const submitDisable = () => {
        const isAdd = !eventDetails
        const commonConditions = priceMarket === '' || selectOption === '' || (isOpenMarketInput===true ? marketPrice==='' :  priceRange === '' || quantity === '') 
        if (isAdd) {
            return commonConditions
        }
        return commonConditions

    }
    useEffect(() => {
        setSubmitDisableButton(submitDisable)
    }, [submitDisable])

    useEffect(() => {
        if (previousProps?.resMessage !== resMessage) {
            if (resMessage) {
                setMessage(resMessage)
                setStatus(resStatus)
                if (resStatus && isCreate) {
                    navigate(`/event-management/event-details/${eventId}`, { state: { message: resMessage } })
                } else {
                    if (resStatus) {
                        setIsEdit(false)
                        dispatch(getEventDetails({ Id: id }))
                    }
                    setModalMessage(true)
                }
                setLoading(false)
            }
        }
        return () => {
            previousProps.resMessage = resMessage
        }
    }, [resStatus, resMessage])
    useEffect(() => {
        modalMessageFunc(modalMessage, setModalMessage, setClose)
    }, [modalMessage])

    function handleChange(event, type) {
        switch (type) {
            case 'priceMarket':
                if (event?.target?.value) {
                    setPriceMarket(event?.target?.value)
                } else {
                    setPriceMarketErr('Required Field')
                }
                if(event.target.value==='MARKET'){
                    setIsOpenMarketInput(true)
                }else{
                    setIsOpenMarketInput(false)
                }
                break
            case 'selectOption':
                if (event?.target?.value) {
                    setSelectOption(event?.target?.value)
                } else {
                    setSelectOptionErr('Required Field')
                }
                break
            case 'priceRange':
                if (event?.target?.value) {
                    setPriceRange(event?.target?.value)
                } else {
                    setPriceRangeErr('Required Field')
                }
                break
            case 'market_price':
                if (event?.target?.value) {
                    setMarketPrice(event?.target?.value)
                } else {
                    setMarketPriceErr('Required Field')
                }
                break
            case 'qunatity':
                if (event.target.value > eventDetails?.oUserConfig?.nMaxBuyQty || event.target.value < eventDetails?.oUserConfig?.nMinBuyQty) {
                    setQuantityErr('Quantity Must be in Range')
                } else if (event?.target?.value) {
                    setQuantity(event?.target?.value)
                    setQuantityErr('')
                } else {
                    setQuantityErr('Required Field')
                }
                break
            case 'stopLoss':
                setStopLoss(event.target.checked)
                break
            case 'stopLossPrice':
                setStopLossPrice(event.target.value)
                break
            case 'bookProfit':
                setBookProfit(event.target.checked)
                break
            case 'bookProfitPrice':
                setBookProfitPrice(event.target.value)
                break
            default:
                break

        }
    }
    function onSubmit(e) {
        let verify = false
        verify = (priceMarket  && selectOption && quantity)
        verify =  (isOpenMarketInput && marketPrice ? true : !isOpenMarketInput && priceRange ? true :false)
        if (verify) {
          if (isCreate) {
            const addOrderData = {
              priceMarket,selectOption,priceRange,quantity,eventId,isOpenMarketInput,marketPrice
            }
            if(stopLoss){
                addOrderData.stopLossPrice = stopLossPrice
            }
            if(bookProfit){
                addOrderData.bookProfitPrice = bookProfitPrice
            }
            dispatch(addOrder(addOrderData))
          } 
        //   else {
        //     const editOrderData = {
        //         priceMarket,selectOption,priceRange,quantity,stopLossPrice,bookProfitPrice,eventId
        //     }
        //      dispatch(updateEventDetails(editOrderData))
        //   }
          setLoading(true)
        } else {
          // if (!bannerType) {
          //   setBannerErr('Required field')
          // }
        }
    }
      
      useImperativeHandle(ref, () => ({
        onSubmit
      }))
    return (
        <>
            <main className="main-content">
                <AlertMessage
                    close={close}
                    message={message}
                    modalMessage={modalMessage}
                    status={status}
                />
                {loading && <Loading />}
                <section className="common-form-block">
                    <Form>
                        <Row className="mb-4 d-flex">
                            <Col md={12} xl={12}>
                                <h2 className="mb-2">{eventDetails?.sName}</h2>
                                <span>{eventDetails?.sDescription}</span>
                                <Row>
                                <Col  md={8}></Col>
                                <Col md={4} className="text-end">
                                <span className="bold-text" style={{color:'#007bff'}}>Yes : {eventDetails?.aAnswerOptions[0]?.nCurrentPrice} {currencySymbol?.Indian} </span>
                                {/* <br /> */}
                                <span className="bold-text" style={{color:'red'}}>No : {eventDetails?.aAnswerOptions[1]?.nCurrentPrice} {currencySymbol?.Indian} </span>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xl={6}>
                                <FormGroup className='form-group mt-2'>
                                    <Label className='edit-label-setting' for="place">
                                        Price Market
                                        {' '}
                                        <RequiredField />
                                    </Label>
                                    <Input
                                        className={priceMarketErr ? 'league-placeholder-error' : 'form-control'}
                                        disabled={adminPermission?.OT_EVENT === 'R'}
                                        name="priceMarket"
                                        onChange={event => handleChange(event, 'priceMarket')}
                                        type="select"
                                        defaultValue={priceMarket || 'LIMIT'}
                                    >
                                        <option value=''>All</option>
                                        <option value='LIMIT'>Limit</option>
                                        <option value='MARKET'>Market</option>
                                    </Input>
                                    <p className="error-text">{priceMarketErr}</p>
                                </FormGroup>
                            </Col>
                            <Col md={12} xl={6}>
                                <FormGroup className='form-group mt-2'>
                                    <Label className='edit-label-setting' for="place">
                                        Option
                                        {' '}
                                        <RequiredField />
                                    </Label>
                                    <Input
                                        className={selectOptionErr ? 'league-placeholder-error' : 'form-control'}
                                        disabled={adminPermission?.OT_EVENT === 'R'}
                                        name="option"
                                        onChange={event => handleChange(event, 'selectOption')}
                                        type="select"
                                        defaultValue={selectOption}
                                    >
                                        <option value=''>All</option>
                                        <option value='YES'>{eventDetails?.aAnswerOptions[0]?.sLabel + ` `}</option>
                                        <option value='NO'>{eventDetails?.aAnswerOptions[1]?.sLabel + ``}</option>
                                    </Input>
                                    <p className="error-text">{selectOptionErr}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                        {isOpenMarketInput ? 
                        <Row>
                        <Col md={1} xl={6}>
                            <FormGroup className='form-group mt-2'>
                                <Label className='edit-label-setting' for="place">
                                    Price
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input
                                    className={marketPriceErr ? 'league-placeholder-error' : 'form-control'}
                                    disabled={adminPermission?.OT_EVENT === 'R'}
                                    name="marketPrice"
                                    onChange={event => handleChange(event, 'market_price')}
                                    type="number"
                                    defaultValue={marketPrice}
                                    min={1}
                                />
                                <p className="error-text">{marketPriceErr}</p>
                            </FormGroup>
                        </Col>
                    </Row>
                        : <Row>
                            <Col md={12} xl={6}>
                                <FormGroup className='form-group mt-2'>
                                    <Row >
                                        <Col md={12} xl={6} className="ps-0">
                                            <Label className='edit-label-setting' for="place">
                                                Price :
                                                {' '}
                                                <RequiredField />
                                            </Label>
                                        </Col>
                                        <Col md={12} xl={6} className="text-end fw-bold">
                                            {currencySymbol?.Indian} {priceRange}
                                        </Col>
                                    </Row>
                                    <Input
                                        className={priceRangeErr ? 'league-placeholder-error' : ''}
                                        disabled={adminPermission?.OT_EVENT === 'R'}
                                        name="option"
                                        onChange={event => handleChange(event, 'priceRange')}
                                        type="range"
                                        defaultValue={priceRange}
                                        min="0.5"
                                        max={9.5}
                                        step={eventDetails?.nPriceDiff || 1}
                                    />
                                    <p className="error-text">{priceRangeErr}</p>
                                </FormGroup>
                            </Col>
                            <Col md={1} xl={6}>
                                <FormGroup className='form-group mt-2'>
                                    <Label className='edit-label-setting' for="place">
                                        Quantity ({eventDetails?.oUserConfig?.nMinBuyQty || 1} - {eventDetails?.oUserConfig?.nMaxBuyQty || 100})
                                        {' '}
                                        <RequiredField />
                                    </Label>
                                    <Input
                                        className={quantityErr ? 'league-placeholder-error' : 'form-control'}
                                        disabled={adminPermission?.OT_EVENT === 'R'}
                                        name="qunatity"
                                        onChange={event => handleChange(event, 'qunatity')}
                                        type="number"
                                        defaultValue={quantity}
                                        min={eventDetails?.oUserConfig?.nMinBuyQty || 1}
                                        max={eventDetails?.oUserConfig?.nMaxBuyQty || 100}
                                    />
                                    <p className="error-text">{quantityErr}</p>
                                </FormGroup>
                            </Col>
                        </Row>}
                        
                        <Row>
                            <Col md={12} xl={6}>
                                <Row className="ps-0">
                                    <Col md={12} xl={6} className="ps-0">
                                        <FormGroup className="form-group mt-2">
                                            <Label className="edit-label-setting" for="stopLoss">
                                                Stop Loss
                                            </Label>
                                            <div className="switch-wrapper">
                                                <Input
                                                    type="checkbox"
                                                    onChange={event => handleChange(event, 'stopLoss')}
                                                    className="custom-switch-input"
                                                    id="stopLoss"
                                                    checked={stopLoss}
                                                />
                                                <Label for="stopLoss" className="custom-switch-label">
                                                    <span className="custom-switch-slider"></span>
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {stopLoss &&
                                        <Col md={12} xl={6} className="ps-0">
                                            <FormGroup className='form-group mt-2' switch>
                                                <Label className='edit-label-setting' for="place">
                                                    Stop Loss Price : {stopLossPrice}
                                                </Label>
                                                <Input
                                                    // className={'form-control'}
                                                    disabled={adminPermission?.OT_EVENT === 'R'}
                                                    name="stopLossPrice"
                                                    onChange={event => handleChange(event, 'stopLossPrice')}
                                                    type="range"
                                                    defaultValue={stopLossPrice}
                                                    min="0.5"
                                                    max={Number(eventDetails?.nTotalPrice) - Number(eventDetails?.nPriceDiff) || 9.5}
                                                    step={eventDetails?.nPriceDiff || 1}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                            <Col md={12} xl={6}>
                                <Row className="ps-0">
                                    <Col md={12} xl={6} className="ps-0">
                                        <FormGroup className="form-group mt-2">
                                            <Label className="edit-label-setting" for="bookProfit">
                                                Book Profit
                                            </Label>
                                            <div className="switch-wrapper">
                                                <Input
                                                    type="checkbox"
                                                    onChange={event => handleChange(event, 'bookProfit')}
                                                    className="custom-switch-input"
                                                    id="bookProfit"
                                                    checked={bookProfit}
                                                />
                                                <Label for="bookProfit" className="custom-switch-label">
                                                    <span className="custom-switch-slider"></span>
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {bookProfit &&
                                        <Col md={12} xl={6} className="ps-0">
                                            <FormGroup className='form-group mt-2' switch>
                                                <Label className='edit-label-setting' for="place">
                                                    Book Profit Price : {bookProfitPrice}
                                                </Label>
                                                <Input
                                                    // className={'form-control'}
                                                    disabled={adminPermission?.OT_EVENT === 'R'}
                                                    name="bookProfitPrice"
                                                    onChange={event => handleChange(event, 'bookProfitPrice')}
                                                    type="range"
                                                    defaultValue={bookProfitPrice}
                                                    min="0.5"
                                                    max={Number(eventDetails?.nTotalPrice) - Number(eventDetails?.nPriceDiff) || 9.5}
                                                    step={eventDetails?.nPriceDiff || 1}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <div className="mt-3">
                        <OrderBook
                            orderBookList={newOrderBookList}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                    {/* <OrderBook eventId={eventId} /> */}
                    </div>
                </section>
            </main>
        </>
    )
})
export default AddOrderForm