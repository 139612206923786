import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useQueryState } from 'react-router-use-location-state'
import { Input, Modal, ModalBody, Row, Col, Button, FormGroup } from 'reactstrap'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import qs from 'query-string'
import PropTypes from 'prop-types'

import editButton from '../../assets/images/edit-pen-icon.svg'
import warningIcon from '../../assets/images/error-warning.svg'

import Loading from '../../components/Loading'
import DataNotFound from '../../components/DataNotFound'
import AlertMessage from '../../components/AlertMessage'
import SkeletonTable from '../../components/SkeletonTable'
import { modalMessageFunc } from '../../helpers/helper'
import getListUserStreak from '../../api/Streak/getListUserStreak'
import { updateStreakData } from '../../actions/streak'

const UserStreakList = forwardRef((props, ref) => {
  const { userStreaks, start, setStart, offset, setOffset, setSearch, isLoading, isFullList } = props
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const exporter = useRef(null)
  const dispatch = useDispatch()

  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])
  const [activePageNo, setPageNo] = useQueryState('page', 1)
  const [startingNo, setStartingNo] = useState(0)
  const [endingNo, setEndingNo] = useState(0)
  const [index, setIndex] = useState(1)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [listLength, setListLength] = useState('10 Rows')
  const [type, setType] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [close, setClose] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const [fullList, setFullList] = useState([])
  const toggleWarning = () => setModalWarning(!modalWarning)

  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const token = useSelector(state => state?.auth?.token)
  const resStatus = useSelector((state) => state?.setting?.resStatus)
  const resMessage = useSelector((state) => state?.setting?.resMessage)
  const obj = qs?.parse(location?.search)
  const searchProp = props?.search
  const previousProps = useRef({ resMessage, resStatus, userStreaks, searchProp, start, offset })?.current
  const paginationFlag = useRef(false)
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.message) {
        setMessage(location?.state?.message)
        setStatus(true)
        setModalMessage(true)
      }
      navigate(location?.pathname, { replace: true })
    }
    let page = 1
    let limit = offset
    if (obj) {
      if (obj?.page) {
        page = obj?.page
        setPageNo(page)
      }
      if (obj?.pageSize) {
        limit = obj?.pageSize
        setOffset(limit)
        setListLength(`${limit} Rows`)
      }
    }
    const startFrom = (page - 1) * offset
    setStart(startFrom)
  }, [])
  //  set settingList
  useEffect(() => {
    if (userStreaks && !isFullList?.current) {
      const userArrLength = userStreaks?.length
      const startFrom = ((activePageNo - 1) * offset) + 1
      const end = startFrom - 1 + userArrLength
      setIndex(activePageNo)
      setStartingNo(startFrom)
      setEndingNo(end)
      setList(userStreaks)
      setTotal(userStreaks?.total ? userStreaks?.total : 0)
    } else if (isFullList.current) {
      setFullList(userStreaks ? userStreaks : [])
      setIndex(activePageNo)
      setTotal(userStreaks?.total ? userStreaks?.total : 0)
      isFullList.current = false
    }
  }, [userStreaks, isFullList?.current])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // to handle response
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        if (resStatus) {
          setMessage(resMessage)
          setStatus(resStatus)
          setModalWarning(false)
          setModalMessage(true)
          setPageNo(activePageNo)
        } else {
          setMessage(resMessage)
          setStatus(resStatus)
          setModalWarning(false)
          setModalMessage(true)
        }
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // to handle query params
  useEffect(() => {
    let data = localStorage?.getItem('queryParams')
      ? JSON?.parse(localStorage?.getItem('queryParams'))
      : {}
    !Object?.keys(data)?.length
      ? (data = { UserStreak: location?.search })
      : (data.UserStreak = location?.search)
    localStorage?.setItem('queryParams', JSON?.stringify(data))
  }, [location?.search])

  // will be called when something searched
  useEffect(() => {
    const callSearchService = () => {
      const startFrom = 0
      setSearch(searchProp?.trim())
      setStart(startFrom)
      setPageNo(1)
    }
    if (previousProps?.searchProp !== searchProp && props?.flag) {
      const deBouncer = setTimeout(() => {
        callSearchService()
      }, 1000)
      return () => {
        clearTimeout(deBouncer)
        previousProps.searchProp = searchProp
      }
    }
    return () => {
      previousProps.searchProp = searchProp
    }
  }, [searchProp])


  function warningWithConfirmMessage (data, eType) {
    setType(eType)
    setSelectedData(data)
    setModalWarning(true)
  }

  function onCancel () {
    toggleWarning()
  }
  // update status from list
  function onStatusUpdate () {
    const status = selectedData?.eStatus === 'Y' ? 'N' : 'Y'
    const updatedData = {
      Title: selectedData?.sTitle,
      day: selectedData?.nDay,
      settingStatus: status,
      amount: selectedData?.nAmount,
      type: selectedData?.eType,
      id:selectedData?._id
    }
    dispatch(updateStreakData(updatedData))
    setTimeout(()=>{
      queryClient?.invalidateQueries('getListUserStreak');
    },200)
    toggleWarning()
  }
  // Export Excel Report List
  const processExcelExportData = (data) => data?.map((userStreaks, index) => {
    const srNo = index + 1
    let eStatus = userStreaks?.eStatus
    eStatus = eStatus === 'Y' ? 'Active' : 'InActive'
    let dCreatedAt = moment(userStreaks?.dCreatedAt)?.local()?.format('MM/DD/YYYY hh:mm A')
    dCreatedAt = dCreatedAt === 'Invalid date' ? ' - ' : dCreatedAt
    const nAmount = userStreaks?.nAmount
    const nDay = userStreaks?.nDay
    const eType = userStreaks?.eType ==='B' ? 'Bonus' : 'Cash'
    return {
      ...userStreaks,
      srNo,
      dCreatedAt,
      eStatus,
      nAmount,
      nDay,
      eType
    }
  })
  // start, offset, sort, 'asc', search, eCategory, isFullList.current
  const exportMutation = useMutation(() => getListUserStreak(0, 10, 'sTitle', '',true), {
    onSuccess: (data) => {
      if (data?.data?.data) {
        exporter.current.props = {
          ...exporter?.current?.props,
          data: processExcelExportData(data?.data?.data || []),
          fileName: 'Streaks.xlsx'
        }
        exporter?.current?.save()
      }
    }
  })

  async function onExport () {
    exportMutation?.mutate()
  }
  useImperativeHandle(ref, () => ({
    onExport
  }))
  return (
    <Fragment>
      <ExcelExport ref={exporter} data={fullList && fullList?.length > 0 ? fullList : list} fileName='Streaks.xlsx'>
        <ExcelExportColumn field='srNo' title='Sr No' />
        <ExcelExportColumn field='eStatus' title='Status' />
        <ExcelExportColumn field='sTitle' title='Title' />
        <ExcelExportColumn field='nAmount' title='Amount' />
        <ExcelExportColumn field='eType' title='Type' />
        <ExcelExportColumn field='nDay' title='Day' />
        <ExcelExportColumn field='dCreatedAt' title='Date & Time' />
      </ExcelExport>
      {!isLoading && list?.length === 0
        ? (<DataNotFound message="Settings" obj={obj}/>)
        : (
          <div className='table-represent'>
            <div className='table-responsive'>
              <AlertMessage
                close={close}
                message={message}
                modalMessage={modalMessage}
                status={status}
              />

              {isLoading && <Loading/>}
              <table className='setting-table'>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Status</th>
                    <th>Day</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Amount</th>
                    {/* <th>Date</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading
                    ? (<SkeletonTable numberOfColumns={9} />)
                    : (
                      <Fragment>
                        {list && list?.length !== 0 && list?.map((data, i) => (
                          <tr key={data?._id}>
                            <td>{(((index - 1) * offset) + (i + 1))}</td>
                            <td>
                            <FormGroup switch>
                              <Input
                                checked={data?.eStatus === 'Y'}
                                id={'id' + data?._id}
                                name={'name' + data?._id}
                                onChange={() => warningWithConfirmMessage(data, data?.eStatus === 'Y' ? 'Inactivate' : 'Activate')}
                                type='switch'
                                />
                              </FormGroup>
                            </td>
                                <td>{data?.nDay || '--'}</td>
                            <td>{data?.sTitle}</td>
                            <td>{data?.eType==='B' ? 'Bonus' : data?.eType==='C' ? 'Cash' : 'Extra' || '--'}</td>
                            <td>{data?.nAmount || '--'}</td>
                            {/* <td>{moment(data?.dCreatedAt)?.format('lll') || '--'}</td> */}
                            <td>
                              <ul className='action-list mb-0 d-flex'>
                                <li>
                                  <NavLink className='view' color='link' to={'/user-streak/streak-details/' + data?._id}>
                                    <Button className='edit-btn-icon'>
                                      <img alt="View" src={editButton} />
                                    </Button>
                                  </NavLink>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                      )}
                </tbody>
              </table>
            </div>
          </div>
          )
      }

      {/* {list?.length !== 0 && (
      <PaginationComponent
        activePageNo={activePageNo}
        endingNo={endingNo}
        listLength={listLength}
        offset={offset}
        paginationFlag={paginationFlag}
        setListLength={setListLength}
        setOffset={setOffset}
        setPageNo={setPageNo}
        setStart={setStart}
        startingNo={startingNo}
        total={total}
      />
      )} */}

      <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
        <ModalBody className='text-center'>
          <img alt='check' className='info-icon' src={warningIcon} />
          <h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
          <Row className='row-12'>
            <Col>
              <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={onCancel} type='submit'>Cancel</Button>
            </Col>
            <Col>
              <Button className='theme-btn danger-btn full-btn' onClick={onStatusUpdate} type='submit'>{`${type} It`}</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  )
})

UserStreakList.propTypes = {
  search: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  flag: PropTypes.bool,
  getList: PropTypes.func,
  // userStreaks: PropTypes.object,
  start: PropTypes.number,
  setStart: PropTypes.func,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
  setSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  isFullList: PropTypes.string,
  setIsFullResponse: PropTypes.func,
  refetch: PropTypes.func,
  isSuccess: PropTypes.bool,
}

UserStreakList.displayName = UserStreakList
export default connect(null, null, null, { forwardRef: true })(UserStreakList)
