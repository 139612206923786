
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'

// import removeImg from '../../../assets/images/ep-close.svg'
import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'

import { verifyLength,modalMessageFunc, } from '../../../helpers/helper'
import getStreakDetails from '../../../api/Streak/getStreakDetails'
import { updateStreakData } from '../../../actions/streak'

const UserStreakDetails = forwardRef((props, ref) => {
    const { adminPermission, Key } = props
    const { id } = useParams()
    const [type, setType] = useState('')
    // const [errType, setErrType] = useState('')
    // const [backgroundImage, setBackgroundImage] = useState('')
    // const [sideImage, setSideImage] = useState('')
    // const [errImage, setErrImage] = useState('')
    const [url, setUrl] = useState('')

    const [Title, setTitle] = useState('')
    const [errTitle, setErrTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState(false)
    const [close, setClose] = useState(false)
    const [settingStatus, setSettingStatus] = useState('N')
    const [day, setDay] = useState(1)
    const [dayErr,setDayErr] = useState('')
    const [amount, setAmount] = useState()
    const [amountErr, setAmountErr] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const resStatus = useSelector(state => state?.streak?.resStatus)
    const resMessage = useSelector(state => state?.streak?.resMessage)
    const getUrlLink = useSelector(state => state?.url?.getUrl)
    const page = JSON?.parse(localStorage?.getItem('queryParams'))
    const [modalMessage, setModalMessage] = useState(false)
    
    //  fetching setting Details by id
    const { data: streakDetails } = useQuery({
        queryKey: ['getStreakDetails', id],
        queryFn: () => getStreakDetails(id),
        select: (res) => res?.data?.data
    })
    const previousProps = useRef({ resStatus, resMessage, Key,streakDetails })?.current
    const buttonDisable = false
    useEffect(() => {
        modalMessageFunc(modalMessage, setModalMessage, setClose)
    }, [modalMessage])
    useEffect(() => {
        if (previousProps?.resMessage !== resMessage) {
            if (resMessage) {
                setMessage(resMessage)
                setStatus(resStatus)
                if (resStatus) {
                    navigate(`/user-streak${page?.UserStreak || ''}`, { state: { message: resMessage } })
                }
                setLoading(false)
            }
        }
        return () => {
            previousProps.resMessage = resMessage
        }
    }, [resMessage, resStatus])
    useEffect(() => {
        if (streakDetails) {
            setTitle(streakDetails?.sTitle)
            setAmount(streakDetails?.nAmount)
            setType(streakDetails?.eType)
            setSettingStatus(streakDetails?.eStatus)
            setDay(streakDetails?.nDay)
            setLoading(false)
        }
        return () => {
            previousProps.streakDetails = streakDetails
        }
    }, [streakDetails])
    useEffect(() => {
        if (getUrlLink) {
            setUrl(getUrlLink)
        }
    }, [getUrlLink])

    function handleChange(event, type) {
        const value = event?.target?.value
        switch (type) {
            case 'Title':
                setErrTitle(verifyLength(value, 1) ? '' : 'Required field')
                setTitle(value)
                break
            case 'Amount':
                if(value){
                    setAmount(value)
                    setAmountErr('')
                }else{
                    setAmountErr('Required Field')
                }
                break
            case 'Status':
                setSettingStatus(value)
                break
            // case 'RemoveImage':
            //     setSideImage('')
            //     setBackgroundImage('')
            //     setErrImage('Required field')
            //     break
            // case 'Type':
            //     if(value){
            //         setType(value)
            //     }else{
            //         setErrType('Required Field')
            //     }
                break
            case 'Day':
                if(value){
                    setDay(value)
                }else{
                    setDayErr('Required Field')
                }
                break
            default:
                break
        }
    }
    function onSubmit(e) {
        const validation = Title !=='' && amount !=='' && type !=='' 
        if (validation) {
            const data = {
                Title,amount,type,settingStatus,id,day
            }
            dispatch(updateStreakData(data))
            setLoading(true)
        } else {
            setErrTitle(!verifyLength(Title, 1) ? 'Required field' : '')
            setAmount('Required Field')
            setType('Required Field')
        }
    }
    useImperativeHandle(ref, () => ({
        onSubmit
    }))
    return (
        <main className="main-content">
            <AlertMessage
                close={close}
                message={message}
                modalMessage={modalMessage}
                status={status}
            />
            {loading && <Loading />}
            <section className="common-form-block">
                <Form>
                    <Row>
                        <Col md={12} xl={12}>
                            <FormGroup>
                                <Label className='edit-label-setting' for="Title">Title </Label>
                                <Input
                                    className="form-control"
                                    disabled={adminPermission?.STREAK === 'R'}
                                    name="Title"
                                    onChange={event => handleChange(event, 'Title')}
                                    type="text"
                                    defaultValue={Title}
                                />
                                <p className="error-text">{errTitle}</p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={6} xl={6}>
                            <FormGroup>
                                <Label className='edit-label-setting' for="Amount">Amount </Label>
                                <Input
                                    className="form-control"
                                    disabled={adminPermission?.STREAK === 'R'}
                                    name="Amount"
                                    onChange={event => handleChange(event, 'Amount')}
                                    type="number"
                                    defaultValue={amount}
                                />
                                <p className="error-text">{amountErr}</p>
                            </FormGroup>
                        </Col>
                        <Col md={6} xl={6}>
                            <FormGroup>
                                <Label className='edit-label-setting' for="Amount">Day </Label>
                                <Input
                                    className="form-control"
                                    disabled={adminPermission?.STREAK === 'R'}
                                    name="Day"
                                    onChange={event => handleChange(event, 'Day')}
                                    type="number"
                                    value={day}
                                />
                                <p className="error-text">{dayErr}</p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={12} xl={12}>
                            <Label className='edit-label-setting' for="Type">Type</Label>
                            <Input
                                className="form-control"
                                disabled={adminPermission?.SETTING === 'R'}
                                name="Type"
                                onChange={event => handleChange(event, 'Type')}
                                type="select"
                                value={type}
                            >
                                <option key='' value=''>
                                    All
                                </option>
                                <option key='B' value='B'>
                                    Bonus
                                </option>
                                <option key='C' value='C'>
                                    Cash
                                </option>
                            </Input>
                        </Col>
                    </Row>
                    <Row className='p-3 mt-3' >
                        <div className='radio-button-div'>
                            <Col md={12} xl={12}>
                                <FormGroup className='form-group'>
                                    <Label className='edit-label-setting lable-league' for="ActiveOffer">Status</Label>
                                    <div className="d-flex inline-input mt-2">
                                        <div className='custom-radio custom-control'>
                                            <Input
                                                checked={settingStatus === 'Y'}
                                                id="bannerRadio1"
                                                label="Active"
                                                name="bannerRadio"
                                                onChange={event => handleChange(event, 'Status')}
                                                type="radio"
                                                value="Y"
                                                className='custom-control-input me-2'
                                            />
                                            <Label>Active</Label>
                                        </div>
                                        <div className='custom-radio custom-control'>
                                            <Input
                                                checked={settingStatus === 'N'}
                                                id="bannerRadio2"
                                                label="In Active"
                                                name="bannerRadio"
                                                onClick={event => handleChange(event, 'Status')}
                                                type="radio"
                                                value="N"
                                                className='custom-control-input me-2'
                                            />
                                            <Label>In Active</Label>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </div>
                    </Row>
                </Form>
            </section>
        </main>
    )
})

UserStreakDetails.defaultProps = {
    history: {}
}

UserStreakDetails.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({
            pathname: PropTypes.string
        })
    }),
    match: PropTypes.object,
    adminPermission: PropTypes.object,
    setSubmitDisableButton: PropTypes.func,
    Auth: PropTypes.string,
    Key: PropTypes.string,
    setKey: PropTypes.func,
    navigate: PropTypes.object

}
UserStreakDetails.displayName = UserStreakDetails
export default connect(null, null, null, { forwardRef: true })(UserStreakDetails)
