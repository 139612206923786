import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import PropTypes from 'prop-types'
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useDispatch, useSelector } from "react-redux"
import AlertMessage from "../../../components/AlertMessage"
import Loading from "../../../components/Loading"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import RequiredField from "../../../components/RequiredField"
import { acceptFormat, isNumber, modalMessageFunc, verifyLength, withoutSpace } from "../../../helpers/helper"
import removeImg from '../../../assets/images/ep-close.svg'

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
import getCMSList from '../../../api/contentManagement/getCMSList'
import updateCMS from '../../../api/contentManagement/updateCMS'
import { addUserXP, updateUserXP } from "../../../actions/userXP"
import getUserXPDetails from "../../../api/UserProfileManagement/getUserXPDetails"
import documentPlaceholder from '../../../assets/images/upload-icon.svg'

const AddUserProfileLeve = forwardRef((props, ref) => {
    const { isCreate, setIsEdit, adminPermission, Name, setName, setSubmitDisableButton, setIsCreate,
        setDescription, Description, minXP, setMinXP, dailyWithdrawalLimit, setDailyWithdrawalLimit, dailyWithdrawalCount, setDailyWithdrawalCount,
        commission, setCommission, commissionFeeType, setCommissionFeeType, hexColor, setHexColor
    } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const [errName, setErrName] = useState('')
    const [errMinXP, setErrMinXP] = useState('')
    const [errwithdrawalLimit, setErrWithdrawalLimit] = useState('')
    const [errwithdrawalCount, setErrWithdrawalCount] = useState('')
    const [errCommission, setErrCommission] = useState('')
    const [errCommissionFeeType, setErrCommissionFeeType] = useState('')
    const [xpImage, setXpImage] = useState('')
    const [url, setUrl] = useState('')
    const [errImage, setErrImage] = useState('')

    const [message, setMessage] = useState('')
    const [status, setStatus] = useState(false)
    const [close, setClose] = useState(false)

    const { id } = useParams()
    const { data: userXPDetails, isLoading } = useQuery({
        queryKey: ['getUserXPDetails', id],
        queryFn: () => getUserXPDetails(id),
        select: (response) => response?.data?.data,
        enabled: !!id
    })
    const resStatus = useSelector(state => state?.userXp?.resStatus)
    const resMessage = useSelector(state => state?.userXp?.resMessage)
  const getUrlLink = useSelector(state => state?.url?.getUrl)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)

    const previousProps = useRef({ resStatus, resMessage, userXPDetails })?.current
    const [modalMessage, setModalMessage] = useState(false)
    const submitDisable = userXPDetails && previousProps?.userXPDetails !== userXPDetails && userXPDetails?.sName === Name && userXPDetails?.sDescription == Description &&
        userXPDetails?.oCriteria?.nMixXP === minXP && userXPDetails?.oRules?.nDailyWithdrawLimit === dailyWithdrawalLimit && userXPDetails?.oRules?.nDailyWithdrawCount === dailyWithdrawalCount &&
        userXPDetails?.oRules?.nCommission === commission && userXPDetails?.oRules?.eCommissionFeeType === commissionFeeType
  useEffect(() => {
    if (getUrlLink) {
      setUrl(getUrlLink)
    }
  }, [getUrlLink])
    useEffect(() => {
        if (userXPDetails && Object.keys(userXPDetails)?.length !== 0) {
            setName(userXPDetails?.sName)
            setDescription(userXPDetails?.sDescription)
            setMinXP(userXPDetails?.oCriteria?.nMinXP)
            setDailyWithdrawalLimit(userXPDetails?.oRules?.nDailyWithdrawLimit)
            setDailyWithdrawalCount(userXPDetails?.oRules?.nDailyWithdrawCount)
            setCommission(userXPDetails?.oRules?.nCommission)
            setCommissionFeeType(userXPDetails?.oRules?.eCommissionFeeType.toUpperCase())
            setHexColor(userXPDetails?.sHexCode)
            setXpImage(userXPDetails?.sImage)
        }
    }, [userXPDetails])
    useEffect(() => {
        setSubmitDisableButton(submitDisable)
    }, [submitDisable])

    useEffect(() => {
        setIsCreate(!id)
        setIsEdit(!!id)
    }, [id])
    useEffect(() => {
        if (previousProps?.resMessage !== resMessage) {
            if (resMessage) {
                setMessage(resMessage)
                setStatus(resStatus)
                setModalMessage(true)
                navigate('/user-profile-level', { state: { message: resMessage } })
            }
        }
        return () => {
            previousProps.resMessage = resMessage
        }
    }, [resStatus, resMessage])
    useEffect(() => {
        modalMessageFunc(modalMessage, setModalMessage, setClose)
    }, [modalMessage])

    useEffect(() => {
        setSubmitDisableButton(submitDisable)
    }, [submitDisable])
    function onSubmit(e) {
        if (verifyLength(Name, 1) && minXP != '' && dailyWithdrawalLimit != '' && dailyWithdrawalCount != '' && commission != '' && hexColor != '' && commissionFeeType!='') {
            if (isCreate) {
                const addUserXPData = {
                    Name, minXP, Description, dailyWithdrawalCount, dailyWithdrawalLimit, commission, commissionFeeType, hexColor,xpImage
                }
                dispatch(addUserXP(addUserXPData))
            } else {

                const updateXPData = {
                    Name, minXP, Description, dailyWithdrawalCount, dailyWithdrawalLimit, commission, commissionFeeType, hexColor, id,xpImage
                }
                dispatch(updateUserXP(updateXPData))
            }
        } else {
            setErrName(!verifyLength(Name, 1) ? 'Required field' : '')
            setErrCommissionFeeType(!verifyLength(commissionFeeType, 1) ? 'Required field' : '')
            setErrCommission(!verifyLength(commission, 1) ? 'Required field' : '')
            setErrMinXP(!verifyLength(minXP, 1) ? 'Required field' : '')
            setErrWithdrawalLimit(!verifyLength(dailyWithdrawalLimit, 1) ? 'Required field' : '')
            setErrWithdrawalCount(!verifyLength(dailyWithdrawalCount, 1) ? 'Required field' : '')
        }
    }

    useImperativeHandle(ref, () => ({
        onSubmit
    }))

    function handleChange(event, type) {
        const value = event?.target?.value
        switch (type) {
            case 'Name':
                if (verifyLength(value, 1)) {
                    setErrName('')
                } else {
                    setErrName('Required field')
                }
                setName(value)
                break
            case 'minXP':
                setErrMinXP(value === '' ? 'Required Field' : '')
                setMinXP(value)
                break
            case 'description':
                setDescription(value)
                break
            case 'withdrawalLimit':
                if (value) {
                    setDailyWithdrawalLimit(value)
                    setErrWithdrawalLimit('')
                } else {
                    setErrWithdrawalLimit('Required Field')
                }
                break
            case 'withdrawalCount':
                if (value) {
                    setDailyWithdrawalCount(value)
                    setErrWithdrawalCount('')
                } else {
                    setErrWithdrawalCount('Required Field')
                }
                break
            case 'commission':
                if (value) {
                    setCommission(value)
                    setErrCommission('')
                } else {
                    setErrCommission('Required Field')
                }
                break
            case 'commissionFeeType':
                if(value){
                    setCommissionFeeType(value)
                    setErrCommissionFeeType('')
                }else{
                    setErrCommissionFeeType('Required Field')
                }
                break
            case 'hexColor':
                setHexColor(value)
                break
            case 'Image':
                if ((event?.target?.files[0]?.size / 1024 / 1024)?.toFixed(2) > 5) {
                    setErrImage('Please select a file less than 5MB')
                } else if (event?.target?.files[0] && event?.target?.files[0]?.type?.includes('image')) {
                    setXpImage({ imageURL: URL?.createObjectURL(event?.target?.files[0]), file: event?.target?.files[0] })
                    setErrImage('')
                }
                break
            case 'RemoveImage':
                setXpImage('')
                break
            default:
                break
        }
    }
  // to handle image error occurred during add time
  function onImageError (ev) {
    ev.target.src = documentPlaceholder
  }

    return (
        <main className="main-content">
            {!isCreate && isLoading && <Loading />}
            <AlertMessage
                close={close}
                message={message}
                modalMessage={modalMessage}
                status={status}
            />

            <section className="common-form-block">
                <Form>
                    <Row>
                        <Col md={12} xl={12}>
                            <FormGroup>
                                <div className="theme-image text-center">
                                    <div className="d-flex theme-photo">
                                        <div className={xpImage ? 'theme-img' : 'theme-img-default new-theme'}>
                                            <img alt="PlayerImage" className={xpImage ? 'custom-img' : 'custom-img-default'} onError={onImageError} src={xpImage ? xpImage?.imageURL ? xpImage?.imageURL : url + xpImage : documentPlaceholder} />
                                            {xpImage && ((Auth && Auth === 'SUPER') || (adminPermission?.PROFILE_LEVEL === 'W')) &&
                                                <div className='remove-img-label'>
                                                    <img alt="removeImage" onClick={event => handleChange(event, 'RemoveImage')} src={removeImg} />
                                                </div>}
                                            {!xpImage && ((Auth && Auth === 'SUPER') || (adminPermission?.PROFILE_LEVEL === 'W')) && !xpImage &&
                                                (
                                                    <div className="custom-file">
                                                        <Input
                                                            accept={acceptFormat()}
                                                            id="exampleCustomFileBrowser"
                                                            name="customFile"
                                                            onChange={event => handleChange(event, 'Image')}
                                                            type="file"
                                                            className="custom-file-input"
                                                        />
                                                        <span className="upload-text">Upload Image</span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <p className="error-text">{errImage}</p>
                                </div>
                            </FormGroup>
                        </Col>
                        {/* <h5 className='mx-4 required-field'>
                            Note : Please upload images with dimensions of 327 × 56 pixels.

                        </h5> */}
                    </Row>
                    <Row>
                        <Col md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="slug">
                                    Name
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input autoComplete='off' className={errName ? 'league-placeholder-error ' : 'league-placeholder'} disabled={adminPermission?.PROFILE_LEVEL === 'R'} name="name" onChange={event => handleChange(event, 'Name')} placeholder="Name" defaultValue={Name} />
                                <p className="error-text">{errName}</p>
                            </FormGroup>
                        </Col>
                        <Col md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="minXP">Min XP {' '}  <RequiredField /></Label>
                                <Input autoComplete='off' className={errMinXP ? 'league-placeholder-error ' : 'league-placeholder'} disabled={adminPermission?.PROFILE_LEVEL === 'R'} name="minXP" onChange={event => handleChange(event, 'minXP')} placeholder="Enter XP" type='number' value={minXP} />
                                <p className="error-text">{errMinXP}</p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3' md={12} xl={12}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="description">Description</Label>
                                <Input autoComplete='off' className='read-only-class' disabled={adminPermission?.CMS === 'R'} name="description" onChange={event => handleChange(event, 'description')} placeholder="Enter Description" type='textarea' defaultValue={Description} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="slug">
                                    Daily Withdrawal Limit
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input autoComplete='off' type="number" className={errwithdrawalLimit ? 'league-placeholder-error ' : 'league-placeholder'} disabled={adminPermission?.PROFILE_LEVEL === 'R'} name="withdrawalLimit" onChange={event => handleChange(event, 'withdrawalLimit')} placeholder="Daily Withdrawal Limit" defaultValue={dailyWithdrawalLimit} />
                                <p className="error-text">{errwithdrawalLimit}</p>
                            </FormGroup>
                        </Col>
                        <Col md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="slug">
                                    Daily Withdrawal Count
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input autoComplete='off' type="number" className={errwithdrawalCount ? 'league-placeholder-error ' : 'league-placeholder'} disabled={adminPermission?.PROFILE_LEVEL === 'R'} name="withdrawalCount" onChange={event => handleChange(event, 'withdrawalCount')} placeholder="Daily Withdrawal Limit"
                                 value={dailyWithdrawalCount}
                                 min={1}
                                 />
                                <p className="error-text">{errwithdrawalCount}</p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="slug">
                                    Commission
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input autoComplete='off' type="number" className={errCommission ? 'league-placeholder-error ' : 'league-placeholder'} disabled={adminPermission?.PROFILE_LEVEL === 'R'} name="commission" onChange={event => handleChange(event, 'commission')} placeholder="Commission" defaultValue={commission} />
                                <p className="error-text">{errCommission}</p>
                            </FormGroup>
                        </Col>
                        <Col md={12} xl={6}>
                            <div className='radio-button-div'>
                                <Col md={12} xl={12}>
                                    <FormGroup className="form-group">
                                        <Label className='edit-label-setting lable-league' for="status">Commission Fee Type
                                        {' '}
                                        <RequiredField />
                                        </Label>
                                        <div className="d-flex inline-input mt-2 ">
                                            <div className="custom-radio custom-control">
                                                <Input
                                                    checked={commissionFeeType === 'P'}
                                                    disabled={adminPermission?.PROFILE_LEVEL === 'R'}
                                                    id="contentRadio1"
                                                    name="themeRadio"
                                                    onChange={event => handleChange(event, 'commissionFeeType')}
                                                    type="radio"
                                                    value="P"
                                                    className='custom-control-input me-2'
                                                />
                                                <Label for="contentRadio1">Percentage</Label>
                                            </div>
                                            <div className="custom-radio custom-control">
                                                <Input
                                                    checked={commissionFeeType === 'F'}
                                                    disabled={adminPermission?.PROFILE_LEVEL === 'R'}
                                                    id="contentRadio2"
                                                    name="themeRadio"
                                                    onChange={event => handleChange(event, 'commissionFeeType')}
                                                    type="radio"
                                                    value="F"
                                                    className='custom-control-input me-2'
                                                />
                                                <Label for="contentRadio2">Fixed</Label>
                                            </div>
                                          
                                        </div>
                                        <p className="error-text">{errCommissionFeeType}</p>
                                    </FormGroup>
                                </Col>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} xl={3}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="slug">
                                    Hex Code
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input autoComplete='off' type="color" className={'league-placeholder'} disabled={adminPermission?.PROFILE_LEVEL === 'R'} name="hexColor" onChange={event => handleChange(event, 'hexColor')} placeholder="Pick Color" value={hexColor} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </section>
        </main>
    )
})
AddUserProfileLeve.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    setIsCreate: PropTypes.func,
    setIsEdit: PropTypes.func,
    setSubmitDisableButton: PropTypes.func,
    Slug: PropTypes.string,
    setSlug: PropTypes.func,
    Category: PropTypes.string,
    setCategory: PropTypes.func,
    Title: PropTypes.string,
    setTitle: PropTypes.func,
    priority: PropTypes.number,
    setPriority: PropTypes.func,
    Details: PropTypes.string,
    setDetails: PropTypes.func
}
export default AddUserProfileLeve