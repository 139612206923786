import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'

import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'
import RequiredField from '../../../components/RequiredField'
import calendarIcon from '../../../assets/images/calendar.svg'

import { modalMessageFunc, verifyLength } from '../../../helpers/helper'
import { getUrl } from '../../../actions/url'
import getCategoryList from '../../../api/category/getCategoryList'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import getSubCategoryList from '../../../api/category/getSubCategoryList'
import DatePicker from 'react-datepicker'
import { addEvent, getAdminBuyCommission, getEventDetails, updateEventDetails } from '../../../actions/events'

const AddEventForm = forwardRef((props, ref) => {
  const { isCreate, setIsEdit, setIsCreate, adminPermission, setSubmitDisableButton } = props
  const { id } = useParams()
  
  const [eventId, setEventId] = useState('')
  const [eventName, setEventName] = useState('')
  const [eventNameErr, setEventNameErr] = useState('')
  const [eventDesc, setEventDesc] = useState('')
  const [eventCategory, setEventCategory] = useState('')
  const [categoryErr, setCategoryErr] = useState('')
  const [eventSubCategory, setEventSubCategory] = useState('')
  const [subCatErr] = useState('')
  const [adminCommissionState, setAdminCommissionState] = useState(0)
  const [minBuyQty, setMinBuyQty] = useState(1)
  const [minBuyQtyErr, setMinBuyQtyErr] = useState('')
  const [maxBuyQty, setMaxBuyQty] = useState(50000)
  const [maxBuyQtyErr, setMaxBuyQtyErr] = useState('')
  const [labelForYes, setLabelForYes] = useState('Yes')
  const [labelForYesErr, setLabelForYesErr] = useState('')
  const [labelForNo, setLabelForNo] = useState('No')
  const [labelForNoErr, setLabelForNoErr] = useState('')
  const [initialQuatityForYes, setInitialQuantityForYes] = useState(50000)
  const [initialQTYForYesErr, setInitialQTYForYesErr] = useState('')
  const [initialQuatityForNo, setInitialQuantityForNo] = useState(50000)
  const [initialQTYForNoErr, setInitialQTYForNoErr] = useState('')
  const [bonus, setBonus] = useState(10)


  const [eventCategoryId, setEventCategoryId] = useState('')
  // const [eventSubCatList, setEventSubCatList] = useState('')
  const [isOpenSubCat, setIsOpenSubCat] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [startDateErr, setStartDateErr] = useState('')
  const [endDate, setEndDate] = useState('');
  const [endDateErr, setEndDateErr] = useState('')
  const [eventTags, setEventTags] = useState('');
  const [eventTagsErr, setEventTagsErr] = useState('');
  // const [tags, setTags] = useState([])

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [close, setClose] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const eventDetails = useSelector(state => state?.events?.eventDetails)
  const resStatus = useSelector(state => state?.events?.resStatus)
  const resMessage = useSelector(state => state?.events?.resMessage)
  const adminCommission = useSelector(state => state?.events?.adminCommission)
  const previousProps = useRef({ resStatus, resMessage, eventDetails, adminCommission })?.current
  const [modalMessage, setModalMessage] = useState(false)
  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0, 50, '', 'Y'],
    queryFn: () => getCategoryList(0, 50, '', 'Y'),
    select: (res) => res?.data?.data?.results
  })

  const {
    data: subCategoryList,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: ['getSubCategoryList', 0, 10, eventCategoryId,'Y'],
    queryFn: ({ pageParam = 0 }) => getSubCategoryList(pageParam, 10, eventCategoryId,'Y'),
    getNextPageParam: (lastPage) => {
      const { total, results } = lastPage?.data?.data;
      const nextPage = results?.length ? results?.length : null;
      return nextPage && results.length < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
  const submitDisable = () => {
    const isAdd = !eventDetails
    const commonConditions = eventName === '' || eventCategory === '' || eventSubCategory === '' || startDate === '' || endDate === '' || eventTags === '' ||
    minBuyQty === '' || maxBuyQty === '' || labelForYes==='' || initialQuatityForYes==='' || labelForNo==='' || initialQuatityForNo===''  || minBuyQtyErr!==''
    if (isAdd) {
      return commonConditions
    }
    const startDate1 = new Date(startDate);
    const hasStarted = startDate1 < new Date();
    return commonConditions || hasStarted

  }
  useEffect(() => {
    if (adminCommission) {
      setAdminCommissionState(adminCommission?.nAmount)
    }
  }, [adminCommission])
  useEffect(() => {
    if (id) {
      dispatch(getEventDetails({ Id: id }))
      setEventId(id)
      setIsCreate(false)
      setLoading(true)
    } else {
      setIsEdit(true)
    }
    dispatch(getAdminBuyCommission())
    dispatch(getUrl('media'))
  }, [])
  useEffect(() => {
    if (previousProps?.eventDetails !== eventDetails) {
      if (eventDetails) {
        setEventName(eventDetails?.sName)
        setEventDesc(eventDetails?.sDescription)
        setEventCategoryId(eventDetails?.oCategory?._id)
        setEventCategory(eventDetails?.oCategory?.eCategoryType)
        if (eventDetails?.iSubCategoryId) {
          setIsOpenSubCat(true)
          setEventSubCategory({
            value: eventDetails?.oSubCategory?._id,
            label: eventDetails?.oSubCategory?.sName
          })
        }
        setStartDate(new Date(eventDetails?.dStartDate))
        setEndDate(new Date(eventDetails?.dEndDate))
        if (eventDetails?.aTags?.length) {
          setEventTags(eventDetails?.aTags.join('\n'))
          // setTags(eventDetails.aTags)
        }
        setAdminCommissionState(eventDetails?.nAdminBuyCommission || 0)
        setLabelForYes(eventDetails?.aAnswerOptions[0]?.sLabel)
        setInitialQuantityForYes(eventDetails?.aAnswerOptions[0]?.nInitialQty || 0)
        setLabelForNo(eventDetails?.aAnswerOptions[1]?.sLabel)
        setInitialQuantityForNo(eventDetails?.aAnswerOptions[1]?.nInitialQty || 0)
        setMinBuyQty(eventDetails?.oUserConfig?.nMinBuyQty || 0)
        setMaxBuyQty(eventDetails?.oUserConfig?.nMaxBuyQty || 0)
        setBonus(eventDetails?.nBonusUtil || 10)
        setLoading(false)
      }
    }
    return () => {
      previousProps.eventDetails = eventDetails
    }
  }, [eventDetails])
  useEffect(() => {
    setSubmitDisableButton(submitDisable)
  }, [submitDisable])
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        if (resStatus && isCreate) {
          navigate('/events/event-management/', { state: { message: resMessage } })
        } else {
          if (resStatus) {
            setIsEdit(false)
            dispatch(getEventDetails({ Id: id }))
          }
          setModalMessage(true)
        }
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // for handle onChange event
  function handleChange(event, type) {
    switch (type) {
      case 'Name':
        if (verifyLength(event?.target?.value, 1)) {
          setEventNameErr('')
        } else {
          setEventNameErr('Required field')
        }
        setEventName(event?.target?.value)
        break
      case 'Description':
        setEventDesc(event?.target?.value)
        break
      case 'categoryType':
        if (!event?.target?.value) {
          setCategoryErr('Required field')
        } else {
          setCategoryErr('')
        }
        setIsOpenSubCat(true)
        const selectedIndex = event.target.selectedIndex;
        const selectedOption = event.target.options[selectedIndex];
        const selectedKey = selectedOption.getAttribute('data-key');
        setEventCategoryId(selectedKey)
        setEventCategory(event?.target?.value)
        break
      case 'startDate':
        if (!event) {
          setStartDateErr('Required field')
        } else {
          setStartDateErr('')
        }
        setStartDate(event);
        if (endDate && event > endDate) {
          setEndDate('');
        }
        break
      case 'endDate':
        if (!event) {
          setEndDateErr('Required field')
        } else {
          setEndDateErr('')
        }
        setEndDate(event)
        break
      case 'tags':
        if (!event?.target?.value) {
          setEventTagsErr('Required field')
        } else {
          setEventTagsErr('')
        }
        setEventTags(event.target.value)
        break
      case 'adminCommission':
        setAdminCommissionState(event.target.value)
        break
      case 'labelForYes':
        if (!event.target.value) {
          setLabelForYesErr('Required Field')
        } else {
          setLabelForYesErr('')
        }
        setLabelForYes(event.target.value)
        break
      case 'labelForNo':
        if (!event.target.value) {
          setLabelForNoErr('Required Field')
        } else {
          setLabelForNoErr('')
        }
        setLabelForNo(event.target.value)
        break
      case 'minBuyQty':
        if (!event?.target?.value) {
          setMinBuyQtyErr('Required field')
        } else if(Number(event?.target?.value)===Number(maxBuyQty)) {
          setMinBuyQtyErr("Min QTY and Max QTY can't be same")
        }else if(Number(event?.target?.value)>Number(maxBuyQty)){
          setMinBuyQtyErr("Min QTY Can't be greater than Max QTY")
        }else{
          setMinBuyQtyErr('')
        }
        setMinBuyQty(event.target.value)
        break
      case 'maxBuyQty':
        if (!event?.target?.value) {
          setMaxBuyQtyErr('Required field')
        } else if(Number(event?.target?.value)===Number(minBuyQty)) {
          setMaxBuyQtyErr("Min QTY and Max QTY can't be same")
        } else if(Number(event?.target?.value)===Number(minBuyQty)) {
          setMinBuyQtyErr("")
          setMaxBuyQtyErr("")
        } else {
          setMaxBuyQtyErr('')
        }
        setMaxBuyQty(event.target.value)
        break
      case 'initialQuantityForYes':
        if (!event.target.value) {
          setInitialQTYForYesErr('Required field')
        } else {
          setInitialQTYForYesErr('')
        }
        setInitialQuantityForYes(event.target.value)
        break
      case 'initialQuantityForNo':
        if (!event.target.value) {
          setInitialQTYForNoErr('Required field')
        } else {
          setInitialQTYForNoErr('')
        }
        setInitialQuantityForNo(event.target.value)
        break
      case 'bonus':
        setBonus(event?.target.value || 0)
        break
      default:
        break
    }
  }
  function onSubmit(e) {
    // let newArr = []
    // if(tags?.length===0 && eventTags){
    //   newArr = [eventTags]
    // }else{
    //   newArr = [...tags]
    // }
    const newArr = eventTags.split('\n').map(tag => tag.trim()).filter(tag => tag);
    let verify = false
    verify = (verifyLength(eventName, 1)  && eventCategory && eventSubCategory && startDate && endDate && newArr )
    const quantityRange = generateRanges(Number(minBuyQty), Number(maxBuyQty))
    if (verify) {
      if (isCreate) {
        const addEventData = {
          eventName, eventDesc, eventCategoryId, eventSubCategory, startDate, endDate, tags:newArr, adminCommissionState, labelForYes, labelForNo, minBuyQty, maxBuyQty, quantityRange, commissionType: adminCommission?.eAmountType, initialQuatityForYes, initialQuatityForNo,bonus
        }
        dispatch(addEvent(addEventData))
      } else {
        const updateEventData = {
          eventId, eventName, eventDesc, eventCategoryId, eventSubCategory, startDate, endDate, tags:newArr, adminCommissionState, labelForYes, labelForNo, minBuyQty, maxBuyQty, quantityRange, commissionType: adminCommission?.eAmountType, initialQuatityForYes, initialQuatityForNo,bonus
        }
        dispatch(updateEventDetails(updateEventData))
      }
      setLoading(true)
    } 
  }
  useImperativeHandle(ref, () => ({
    onSubmit
  }))
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && eventTags.trim()) {
  //     const newTags = eventTags.split('\n').map(tag => tag.trim()).filter(tag => tag);
  //     if (newTags.length) {
  //       setTags(prevTags => [
  //         ...prevTags,
  //         ...newTags.filter(tag => !prevTags.includes(tag))
  //       ]);
  //     }
  //   }
  // };
  const generateRanges = (min, max) => {
    const minS = parseInt(min, 10);
    const maxS = parseInt(max, 10);
    const length = 5;
    const step = (maxS - minS) / length;
  
    const newRanges = Array.from({ length }, (_, index) => {
      const start = minS + index * step;
      const end = minS + (index + 1) * step;
      return {
        nMin: Math.round(start),
        nMax: Math.round(index === length - 1 ? maxS : end),
      };
    });
  
    return newRanges;
  };

  const options = subCategoryList?.map(subCat => ({
    value: subCat._id,
    label: `${subCat?.sName}`,
  })) || [];
  const ExampleCustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <div className='form-control date-range' onClick={onClick}>
      <img alt="calendar" src={calendarIcon} />
      <Input ref={ref} className='mx-2 range' placeholder={placeholder} readOnly value={value} />
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput
  return (
    <main className="main-content">
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      {loading && <Loading />}
      <section className="common-form-block">
        <Form>
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Event Name
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={eventNameErr ? 'league-placeholder-error' : 'form-control'}
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="eventName"
                  onChange={event => handleChange(event, 'Name')}
                  type="text"
                  defaultValue={eventName}
                />
                <p className="error-text">{eventNameErr}</p>
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Event Description
                  {' '}
                </Label>
                <Input
                  className={'form-control'}
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="eventDescription"
                  onChange={event => handleChange(event, 'Description')}
                  type="textarea"
                  defaultValue={eventDesc}
                />
              </FormGroup>
            </Col>
          </Row> */}
          <Row>
            <Col md={12} xl={6}>
              <FormGroup className='form-group mt-2'>
                <Label className='edit-label-setting' for="place">
                  Category Type
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={categoryErr ? 'league-placeholder-error' : 'form-control'}
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="category"
                  onChange={event => handleChange(event, 'categoryType')}
                  type="select"
                  value={eventCategory}
                >
                  <option value=''>Select Category</option>
                  {categoryList?.map((category) => {
                    return <option value={category?.eCategoryType} data-key={category?._id}>{category?.sName}</option>
                  })}
                </Input>
                <p className="error-text">{categoryErr}</p>
              </FormGroup>
            </Col>
            {isOpenSubCat &&
              <Col md={12} xl={6}>
                <FormGroup className='form-group mt-2'>
                  <Label className='edit-label-setting' for="subCat">
                    Sub Category
                    {' '}
                    <RequiredField />
                  </Label>
                  <Select
                    className={subCatErr ? 'league-placeholder-error' : 'subAdmin-log'}
                    options={options}
                    isDisabled={adminPermission?.OT_EVENT === 'R'}
                    onMenuScrollToBottom={() => {
                      if (hasNextPage && !isFetching) {
                        fetchNextPage();
                      }
                    }}
                    onChange={(selectedOption) => setEventSubCategory(selectedOption)}
                    placeholder='Select Sub Category'
                    value={eventSubCategory}
                  />
                  <p className="error-text">{subCatErr}</p>
                </FormGroup>
              </Col>
            }
          </Row>
          <Row className='event-form'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Select Start Date
                  {' '}
                  <RequiredField />
                </Label>
                <DatePicker
                  className={startDateErr ? 'league-placeholder-error' : 'form-control'}
                  customInput={<ExampleCustomInput placeholder="Select Start Date" />}
                  dropdownMode="select"
                  isClearable={true}
                  minDate={new Date()}
                  minTime={new Date()}
                  maxTime={new Date(0, 0, 0, 23, 59)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select Start Date and Time"
                  selectsStart
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => handleChange(date, 'startDate')}
                  selected={startDate}
                />
                <p className="error-text">{startDateErr}</p>
              </FormGroup>
            </Col>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Select End Date
                  {' '}
                  <RequiredField />
                </Label>
                <DatePicker
                  className={endDateErr ? 'league-placeholder-error' : 'form-control'}
                  customInput={<ExampleCustomInput placeholder="Select End Date" />}
                  dropdownMode="select"
                  isClearable={true}
                  minDate={startDate || new Date()} // Ensure end date is after start date
                  minTime={startDate && endDate && startDate.toDateString() === endDate.toDateString() ? startDate : new Date(0, 0, 0, 0, 0)}
                  maxTime={new Date(0, 0, 0, 23, 59)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select End Date and Time"
                  selectsEnd
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => handleChange(date, 'endDate')}
                  selected={endDate}
                  disabled={!startDate} // Disable if start date is not selected
                />
                <p className="error-text">{endDateErr}</p>
              </FormGroup>
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Tags
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={eventTagsErr ? 'league-placeholder-error' : 'form-control'}
                  type="textarea"
                  placeholder="Enter a tag and press Enter"
                  defaultValue={eventTags}
                  onChange={(e) => handleChange(e, 'tags')}
                  // onKeyDown={handleKeyDown}
                />
                <p className="error-text">{eventTagsErr}</p>
              </FormGroup>
            </Col>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='commission'>
                  Admin Commission %
                  {' '}
                </Label>
                <Input
                  type='number'
                  value={adminCommissionState}
                  onChange={(e) => handleChange(e, 'adminCommission')}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='labelYes'>
                  Label for Yes Option
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={labelForYesErr ? 'league-placeholder-error' : 'form-control'}
                  type='text'
                  value={labelForYes}
                  placeholder='Enter Label'
                  onChange={(e) => handleChange(e, 'labelForYes')}
                />
                <p className="error-text">{labelForYesErr}</p>
              </FormGroup>
            </Col>

            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='labelYes'>
                  Initial Quantity for Yes Option
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={initialQTYForYesErr ? 'league-placeholder-error' : 'form-control'}
                  type='number'
                  value={initialQuatityForYes}
                  placeholder='Enter Initial Quantity for Yes option'
                  onChange={(e) => handleChange(e, 'initialQuantityForYes')}
                />
                <p className="error-text">{initialQTYForYesErr}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='labelYes'>
                  Label for No Option
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={labelForNoErr ? 'league-placeholder-error' : 'form-control'}
                  type='text'
                  value={labelForNo}
                  placeholder='Enter Label'
                  onChange={(e) => handleChange(e, 'labelForNo')}
                />
                  <p className="error-text">{labelForNoErr}</p>
              </FormGroup>
            </Col>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='labelYes'>
                  Initial Quantity for No Option
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={initialQTYForNoErr ? 'league-placeholder-error' : 'form-control'}
                  type='number'
                  value={initialQuatityForNo}
                  placeholder='Enter Initial Quantity for No option'
                  onChange={(e) => handleChange(e, 'initialQuantityForNo')}
                />
                <p className="error-text">{initialQTYForNoErr}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='labelYes'>
                  Min Buy Quantity
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={minBuyQtyErr ? 'league-placeholder-error' : 'form-control'}
                  type='number'
                  value={minBuyQty}
                  placeholder='Enter Min Buy Quantity'
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || parseInt(value) > 0) {
                      handleChange(e, 'minBuyQty');
                    }
                  }}
                  // onChange={(e) => handleChange(e, 'minBuyQty')}
                  min="1" 
                />
                <p className="error-text">{minBuyQtyErr}</p>
              </FormGroup>
            </Col>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='labelYes'>
                  Max Buy Quantity
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={maxBuyQtyErr ? 'league-placeholder-error' : 'form-control'}
                  type='number'
                  value={maxBuyQty}
                  placeholder='Enter Max Buy Quantity'
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || parseInt(value) > 0) {
                      handleChange(e, 'maxBuyQty')
                    }
                  }}
                  // onChange={(e) => handleChange(e, 'maxBuyQty')}
                  min="1" 
                />
                <p className="error-text">{maxBuyQtyErr}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
          <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='labelYes'>
                  Bonus
                  {' '}
                </Label>
                <Input
                  className={'form-control'}
                  type='number'
                  value={bonus}
                  placeholder='Enter Bonus'
                  onChange={(e) => {
                      handleChange(e, 'bonus')
                  }}
                  min="0" 
                />
              </FormGroup>
            </Col>
          </Row>

        </Form>
      </section>
    </main>
  )
})

AddEventForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }),
  match: PropTypes.object,
  isEdit: PropTypes.string,
  setIsEdit: PropTypes.func,
  adminPermission: PropTypes.object,
  Auth: PropTypes.string,
  setSubmitDisableButton: PropTypes.func
}

AddEventForm.displayName = AddEventForm
export default connect(null, null, null, { forwardRef: true })(AddEventForm)
